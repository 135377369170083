import { Button, Checkbox, DatePicker, Divider, Dropdown, Input, notification, Select, Space, Tooltip } from "antd";
import Icon, { CloseOutlined, DeleteOutlined, UploadOutlined, UsergroupAddOutlined, CheckSquareOutlined, CalendarOutlined, UserOutlined, PlusOutlined, PaperClipOutlined } from '@ant-design/icons';
import { useTranslate } from "../../../components/Translate";
import React, { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { formatSizeInMB, getParsedDate } from "../../../components/globalFunctions";
import { LoadingItem } from "../../../components/Loading/Loading";
import { getEnvSettings } from "../../../config/config";
import { apiSecure } from "../../../components/api";
import dayjs from "dayjs";
import useToken from "antd/es/theme/useToken";
import { FileUpload, FileUploadDropZone } from "../../../components/FileUpload/FileUpload";
import { FileModal } from "../../../components/FileModal/FileModal";
import { useDropzone } from 'react-dropzone'

const { TextArea } = Input;


const SavedSvg = () => (
  <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g clipPath="url(#clip0_1647_258)">
      <path d="M10.9375 6.5625C10.6944 4.11414 8.99609 2.625 7 2.625C5.11328 2.625 3.89813 3.94297 3.5 5.25C1.85938 5.41406 0.4375 6.44191 0.4375 8.3125C0.4375 10.1172 1.91406 11.375 3.71875 11.375H10.8281C12.332 11.375 13.5625 10.6247 13.5625 8.96875C13.5625 7.33305 12.1133 6.62375 10.9375 6.5625Z" stroke="#2BF263" strokeWidth="0.9" strokeLinejoin="round" />
      <path d="M8.66797 5.68457L5.72031 9.18457L4.45703 7.78457" stroke="#2BF263" strokeWidth="0.9" strokeLinecap="round" strokeLinejoin="round" />
    </g>
    <defs>
      <clipPath id="clip0_1647_258">
        <rect width="14" height="14" fill="white" />
      </clipPath>
    </defs>
  </svg>
);
const SavedIcon = (props) => <Icon component={SavedSvg} {...props} />;

const UnSavedSvg = () => (
  <svg width="16" height="12" viewBox="0 0 16 12" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M13.25 11.249L2.75 0.749023" stroke="#BA3434" strokeWidth="0.9" strokeMiterlimit="10" strokeLinecap="round" />
    <path d="M13.792 9.32687C14.2683 8.93203 14.5625 8.33895 14.5625 7.5293C14.5625 5.89359 13.1133 5.1843 11.9375 5.12305C11.6944 2.67469 9.99609 1.18555 8 1.18555C7.28469 1.18555 6.6659 1.37504 6.15156 1.68156M3.56266 4.00879C2.35324 4.41348 1.4375 5.37187 1.4375 6.87305C1.4375 8.67773 2.91406 9.93555 4.71875 9.93555H9.76012L3.56266 4.00879Z" stroke="#BA3434" strokeWidth="0.9" strokeLinecap="round" strokeLinejoin="round" />
  </svg>
);
const UnSavedIcon = (props) => <Icon component={UnSavedSvg} {...props} />;


const SaveSvg = () => (

  <svg width="15" height="15" viewBox="0 0 15 15" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M9.375 10.7744H11.6016C13.2129 10.7744 14.5312 9.91865 14.5312 8.32519C14.5312 6.73174 12.9785 5.93838 11.7188 5.87598C11.4583 3.38398 9.63867 1.86816 7.5 1.86816C5.47852 1.86816 4.17656 3.20967 3.75 4.54004C1.99219 4.70703 0.46875 5.82559 0.46875 7.65723C0.46875 9.48887 2.05078 10.7744 3.98438 10.7744H5.625" stroke="#1620EB" strokeWidth="0.9" strokeLinecap="round" strokeLinejoin="round" />
    <path d="M9.375 7.49609L7.5 5.62109L5.625 7.49609M7.5 13.1334V6.08984" stroke="#1620EB" strokeWidth="0.9" strokeLinecap="round" strokeLinejoin="round" />
  </svg>

);
const SaveIcon = (props) => <Icon component={SaveSvg} {...props} />;






export const ProjectNoteHeader = ({ note, closeNote, saveNoteManual, updateNoteTitle, noteSize, maxNoteSize, saved, saving, participantsText, showParticipants, setShowParticipants, canDeleteNote, showTasks, setShowTasks, showFiles, setShowFiles }) => {
  const { locale } = useTranslate();
  const [isEditing, setIsEditing] = useState(false); // Muokkaustila
  const [title, setTitle] = useState(note?.Otsikko); // Otsikon tila
  const [saveTextHover, setSaveTextHover] = useState(false);

  useEffect(() => {
    if (!note?.MuistioNro) return
    setTitle(note?.Otsikko)
  }, [note]);

  // Tallenna muutos
  const handleSave = async () => {
    setIsEditing(false); // Poistu muokkaustilasta
    if (title !== note?.Otsikko) {
      // Tee API-kutsu tallentaaksesi muutos tietokantaan
      updateNoteTitle(title);
    }
  };

  // Tallenna, kun kenttä menettää fokuksen (onBlur)
  const handleBlur = () => {
    handleSave();
  };

  // Päivitä tila, kun käyttäjä tekee muutoksia
  const handleChange = (e) => {
    setTitle(e.target.value);
  };

  return (
    <div className="project-note-header">
      <div>
        <div style={{ display: 'flex', flexDirection: 'row' }}>
          <p className="project-note-header-date font-dm-medium">{getParsedDate(note?.Paiva)}</p>
          <Tooltip title={locale.note_size} color={"#353535"}>
            <p className={`project-note-header-size ${noteSize >= maxNoteSize ? 'failed' : ''} font-dm-medium`}>{formatSizeInMB(noteSize)} / {formatSizeInMB(maxNoteSize)}</p>
          </Tooltip>

          <div
            onMouseEnter={() => setSaveTextHover(true)}
            onMouseLeave={() => setSaveTextHover(false)}
          >
            <p
              className={`project-note-header-size ${(!saved && !saveTextHover) ? 'failed' : ''} clickable font-dm-medium`}
              onClick={saveNoteManual}
            >
              {saving ? (
                <LoadingItem color="black" />
              ) : (
                <>
                  {saveTextHover ? (
                    <>
                      <SaveIcon /> {/* Näytetään hoverin aikana */}
                      {locale.save}
                    </>
                  ) : saved ? (
                    <>
                      <SavedIcon /> {/* Näytetään kun sisältö on tallennettu */}
                      {locale.note_saved}
                    </>
                  ) : (
                    <>
                      <UnSavedIcon /> {/* Näytetään jos sisältö ei ole tallennettu */}
                      {locale.note_unsaved}
                    </>
                  )}
                </>
              )}
            </p>
          </div>



        </div>

        {isEditing ? (
          <input
            type="text"
            className="project-note-header-title font-dm-bold"
            value={title}
            onChange={handleChange}
            onBlur={handleBlur} // Kun menettää fokuksen, tallennetaan
            onKeyDown={(e) => {
              if (e.key === 'Enter') handleSave(); // Tallennetaan, kun painetaan Enter
            }}
            autoFocus
          />
        ) : (
          <p
            className="project-note-header-title font-dm-bold"
            onClick={() => setIsEditing(true)} // Muokkaustilaan klikatessa
          >
            {title}
          </p>
        )}
      </div>


      <div className="project-note-header-right">
        <CheckSquareOutlined
          className={`project-note-header-icon ${showTasks ? 'disabled' : ''}`}
          onClick={() => {
            setShowTasks(!showTasks)
          }}
        />

        <PaperClipOutlined
          className={`project-note-header-icon ${showFiles ? 'disabled' : ''}`}
          onClick={() => {
            setShowFiles(!showFiles)
          }}
        />

        <UsergroupAddOutlined
          className={`project-note-header-icon ${showParticipants ? 'disabled' : ''}`}
          onClick={() => {
            setShowParticipants(!showParticipants)
          }}
        />

        {/*         <UploadOutlined
          className={`project-note-header-icon disabled`}
          onClick={() => { }}
        /> */}

        <DeleteOutlined
          className={`project-note-header-icon`}
          onClick={canDeleteNote}
        />

        <CloseOutlined
          className={`project-note-header-close ${saving ? 'failed' : ''}`}
          onClick={closeNote}
        />
      </div>

    </div>
  );
};

export const ProjectNoteParticipants = ({ note, participantsText, setParticipantsText, showParticipants, setShowParticipants, updateNoteParticipants }) => {

  // Tallenna, kun kenttä menettää fokuksen (onBlur)
  const handleBlur = () => {
    if (participantsText !== note?.Osallistujat) {
      updateNoteParticipants(participantsText);
    }
  };

  return (<>
    {showParticipants ?
      <div className="project-note-participants">
        <p className="project-note-participants-header font-dm-bold">Osallistujat</p>

        <TextArea
          autoSize={{
            minRows: 3
          }}
          onBlur={handleBlur}
          style={{
            border: 0,
            paddingLeft: 0
          }}
          value={participantsText}
          onChange={(e) => {
            setParticipantsText(e.target.value)
            console.log("onChange", e.target.value)
          }} />

      </div>
      : <></>}
  </>)
}




{/* Käyttäjäm valinta taskiin */ }
const UserSelectDropdown = ({ taskId, value, workersArr, handleEditTaskUser, userOpenTaskIndex, setUserOpenTaskIndex }) => {
  const [name, setName] = useState('');

  const onNameChange = (event) => {
    setName(event.target.value);
  };

  const handleChange = (value) => {
    let worker = workersArr.find(itm => itm.value === value);
    handleEditTaskUser(value, worker?.label, taskId)
  };

  const handleAddCustom = () => {
    console.log(`handleAddCustom:`, name, taskId);
    setUserOpenTaskIndex(null)
    handleEditTaskUser(null, name, taskId)
  };

  return (
    <Select
      className="user-select-custom"
      style={{ width: 0, visibility: 'hidden' }}
      dropdownStyle={{ width: 200 }}
      placement="bottomLeft"
      options={workersArr}
      value={value}
      open={userOpenTaskIndex === taskId}
      onFocus={() => {
        setUserOpenTaskIndex(taskId)
      }}
      onDropdownVisibleChange={(open) => {
        if (!open) {
          setUserOpenTaskIndex(null)
        }
      }}
      variant="borderless"
      onChange={handleChange}
      dropdownRender={(menu) => (
        <>
          {menu}
          <Divider style={{ margin: '8px 0' }} />
          <Space style={{ padding: '0 8px 4px' }}>
            <Input
              placeholder="Kirjoita"
              value={name}
              onChange={onNameChange}
              onKeyDown={(e) => {
                e.stopPropagation()

                if (e.key === 'Enter') {
                  handleAddCustom()
                }
              }}
            />
          </Space>
        </>
      )}
    />
  )
};


export const ProjectNoteTasks = ({ noteId, workers, initialTasks, showTasks, setShowTasks }) => {
  const envConfig = getEnvSettings();

  const [tasks, setTasks] = useState([]); // Tehtävien lista
  const [newTask, setNewTask] = useState(''); // Uusi tehtävä
  const [editingIndex, setEditingIndex] = useState(null); // Indeksi, jota muokataan
  const [editingTask, setEditingTask] = useState(''); // Muokattava tehtävä

  const [taskHovered, setTaskHovered] = useState(null); // Muokattava tehtävä


  const [loadingTaskIndex, setLoadingTaskIndex] = useState(null); // Muokattava tehtävä
  const [dateOpenTaskIndex, setDateOpenTaskIndex] = useState(null);
  const [userOpenTaskIndex, setUserOpenTaskIndex] = useState(null);

  const [workersArr, setWorkersArr] = useState([]); // Muokattava tehtävä

  const [currentDate, setCurrentDate] = useState(new Date().setHours(0, 0, 0, 0));



  useEffect(() => {
    console.log('workers', workers);
    if (workers.length >= 1) {
      let workArr = workers
        .map((item) => ({ label: item.Henkilo, value: item.TyontekijaNRO }))
        .sort((a, b) => a.label.localeCompare(b.label)); // Lajitellaan nimen mukaan
      setWorkersArr(workArr);
    }
  }, [workers]);

  //Muodosttaan tietokannan taskeista array
  useEffect(() => {
    console.log('initialTasks', initialTasks)
    if (initialTasks.length >= 1) {
      let initArray = []

      initialTasks.map((itm) => {
        const newTaskObject = {
          id: itm.ToimenpideNro,
          title: itm.Toimenpide,
          completed: itm.Valmis ? true : false, // Alustetaan uusi tehtävä ei-valmiiksi
          date: itm.Maaraaika,
          userName: itm.Vastuuhenkilo ? itm.VastuuhenkiloNimi : itm.VastuuhenkiloMuu,
        };
        initArray.push(newTaskObject)
      })
      console.log('initArray', initArray)
      setTasks(initArray);
      setShowTasks(true)
    }
  }, [initialTasks]);


  const newNoteTask = () => {
    if (loadingTaskIndex) return
    const taskText = newTask
    if (taskText.trim() === '') return     //Ei tyhjiä taskeja
    setLoadingTaskIndex('new')
    let req_config = {
      method: "POST",
      url: `${envConfig.apiUrlPrefix}newNoteTask`,
      data: {
        noteId: noteId,
        taskText: taskText
      },
    };

    apiSecure(req_config)
      .then((response) => {
        const { status, taskId } = response?.data
        console.log('response?.data', response?.data)
        if (status && taskId) {
          addTaskLocal(taskId, taskText)
        }
      })
      .catch((error) => {
        console.log("Error:", error);
      });
  };

  const deleteNoteTask = (taskId) => {
    if (loadingTaskIndex) return
    setLoadingTaskIndex(taskId)

    let req_config = {
      method: "POST",
      url: `${envConfig.apiUrlPrefix}deleteNoteTask`,
      data: {
        taskId: taskId,
      },
    };

    apiSecure(req_config)
      .then((response) => {
        const { status } = response?.data
        if (status) {
          removeTaskLocal(taskId)
        }
      })
      .catch((error) => {
        console.log("Error:", error);
      });
  };


  const updateNoteTask = (taskId, type, value) => {
    return new Promise((resolve, reject) => {
      if (loadingTaskIndex) return
      setLoadingTaskIndex(taskId)

      let req_config = {
        method: "POST",
        url: `${envConfig.apiUrlPrefix}updateNoteTask`,
        data: {
          taskId: taskId,
          type: type,
          value: value,
        },
      };

      apiSecure(req_config)
        .then((response) => {
          const { status } = response?.data;
          if (status) {
            setLoadingTaskIndex(null)
            resolve(true);  // Palautetaan true jos päivitys onnistuu
          } else {
            setLoadingTaskIndex(null)
            resolve(false); // Palautetaan false jos päivitys epäonnistuu
          }

        })
        .catch((error) => {
          console.log("Error:", error);
          resolve(false);  // Palautetaan false virheen tapauksessa
        });
    });
  };



  //Taskin lisäys client puolella
  const addTaskLocal = (taskId, taskText) => {
    const newTaskObject = {
      id: taskId,
      title: taskText,
      completed: false, // Alustetaan uusi tehtävä ei-valmiiksi
      date: null
    };
    console.log('addTaskLocal', newTaskObject)
    setTasks([...tasks, newTaskObject]); // Lisätään uusi tehtävä listaan objektina
    setNewTask(''); // Tyhjennetään kirjoituskenttä
    setLoadingTaskIndex(null)
  };

  const removeTaskLocal = (taskId) => {
    const updatedTasks = tasks.filter((task) => task.id !== taskId); // Poistetaan tehtävä taskId:n perusteella
    setTasks(updatedTasks); // Päivitetään tasks-tila ilman poistettua tehtävää

    setLoadingTaskIndex(null)
    notification.open({
      message: "Toimenpide poistettu onnistuneesti!",
      placement: 'top',
      type: 'success'
    });
  };

  //Taskin otsikon muokkaus
  const handleEditTaskText = async (taskId) => {
    const newtTaskText = editingTask
    const updatedTasks = [...tasks]; // Kopioidaan tehtävälista
    const taskIndex = updatedTasks.findIndex((task) => task.id === taskId); //Etsitään oikea task index

    if (taskIndex === -1) { //Taskia ei jostain syystö löydy
      setEditingIndex(null);
      return
    }

    const oldTaskText = updatedTasks[taskIndex].title

    if (oldTaskText === newtTaskText) {
      setEditingIndex(null);
      return
    }

    console.log('handleEditTaskText', taskId, newtTaskText, newtTaskText)
    let status = await updateNoteTask(taskId, "title", newtTaskText)

    if (status) {
      const updatedTasks = [...tasks]; // Kopioidaan tehtävälista
      const taskIndex = updatedTasks.findIndex((task) => task.id === taskId); //Etsitään oikea task index

      if (taskIndex !== -1) {
        updatedTasks[taskIndex] = {
          ...updatedTasks[taskIndex],
          title: newtTaskText,    // Päivitetään otsikko
        };

        console.log('updatedTasks', updatedTasks)
        setTasks(updatedTasks);
        setEditingIndex(null); // Lopetetaan muokkaus

        notification.open({
          message: "Toimenpide päivitetty!",
          placement: 'top',
          type: 'success'
        });
      }
    } else {
      notification.open({
        message: "Toimenpiteen päivityksessä ongelma!",
        placement: 'top',
        type: 'error'
      });
    }
  };

  //Taskin cb muokkaus
  const handleEditTaskReady = async (taskId) => {
    const updatedTasks = [...tasks];
    const taskIndex = updatedTasks.findIndex((task) => task.id === taskId); //Etsitään oikea task index
    if (taskIndex === -1) return //Taskia ei jostain syystö löydy
    const newTaskReady = !updatedTasks[taskIndex].completed
    console.log('handleEditTaskReady', taskId, newTaskReady)

    let status = await updateNoteTask(taskId, "ready", newTaskReady)

    if (status) {
      updatedTasks[taskIndex] = {
        ...updatedTasks[taskIndex],
        completed: newTaskReady,    // Päivitetään otsikko
      };

      setTasks(updatedTasks);
      setEditingIndex(null); // Lopetetaan muokkaus

      notification.open({
        message: "Toimenpide päivitetty!",
        placement: 'top',
        type: 'success'
      });
    } else {
      notification.open({
        message: "Toimenpiteen päivityksessä ongelma!",
        placement: 'top',
        type: 'error'
      });
    }
  };

  const handleEditTaskDate = async (selectedDate, taskId) => {
    const updatedTasks = [...tasks];
    const taskIndex = updatedTasks.findIndex((task) => task.id === taskId); //Etsitään oikea task index
    if (taskIndex === -1) return //Taskia ei jostain syystö löydy
    const saveDate = dayjs(selectedDate).format("YYYY-MM-DD") //Tietokantaa varten tähän tmalliin
    console.log('handleEditTaskDate', saveDate, taskId)

    let status = await updateNoteTask(taskId, "date", saveDate)

    if (status) {
      updatedTasks[taskIndex] = {
        ...updatedTasks[taskIndex],
        date: saveDate
      };

      setTasks(updatedTasks);
      /*   setEditingIndex(null); // Lopetetaan muokkaus */

      notification.open({
        message: "Toimenpide päivitetty!",
        placement: 'top',
        type: 'success'
      });
    } else {
      notification.open({
        message: "Toimenpiteen päivityksessä ongelma!",
        placement: 'top',
        type: 'error'
      });
    }
  };

  const handleEditTaskUser = async (userId, userName, taskId) => {
    console.log('handleEditTaskUser', userId, userName, taskId)
    const updatedTasks = [...tasks];
    const taskIndex = updatedTasks.findIndex((task) => task.id === taskId); //Etsitään oikea task index
    if (taskIndex === -1) return //Taskia ei jostain syystö löydy

    let status = await updateNoteTask(taskId, "user", {
      userId: userId,
      userName: userName
    })

    if (status) {
      updatedTasks[taskIndex] = {
        ...updatedTasks[taskIndex],
        userName: userName
      };

      setTasks(updatedTasks);


      notification.open({
        message: "Vastuuhenkilö päivitetty!",
        placement: 'top',
        type: 'success'
      });
    } else {
      notification.open({
        message: "Vastuuhenkilön päivityksessä ongelma!",
        placement: 'top',
        type: 'error'
      });
    }
  };






  const RenderTaskRow = ({ task }) => {
    // Tehtävän päivämäärä ilman aikaa
    const taskDate = task.date ? new Date(task.date) : null;
    let isOld = false

    if (taskDate) {
      taskDate.setHours(0, 0, 0, 0);
      isOld = taskDate < currentDate;
    }

    //console.log('isOld', isOld, taskDate, task)

    return (

      <div
        key={task.id}
        style={{ display: 'flex', flexDirection: 'row' }}
        onMouseEnter={() => {
          if (dateOpenTaskIndex || userOpenTaskIndex) return
          setTaskHovered(task.id)
        }}
        onMouseLeave={() => {
          if (dateOpenTaskIndex || userOpenTaskIndex) return
          setTaskHovered(null)
        }}
      >
        {/* Checkbox */}
        {taskHovered === task.id ? <>
          <input
            className="project-note-tasks-checkbox"
            type="checkbox"
            checked={task.completed}
            onChange={() => handleEditTaskReady(task.id)} // Vaihdetaan tehtävän "completed"-tila
          />
        </> : <div className="project-note-tasks-checkbox-placeholder" />}


        <div
          className={`project-note-tasks-item ${task.completed ? 'completed' : ''} ${isOld && !task.completed ? 'isOld' : ''}`}
        >
          {editingIndex === task.id ? <>
            {/* Taskin nimen muokkaus */}
            <input
              maxLength={50}
              className="project-note-task-input project-note-task-width font-dm-regular"
              type="text"
              value={editingTask}
              onChange={(e) => setEditingTask(e.target.value)}
              onKeyDown={(e) => {
                if (e.key === 'Enter') {
                  handleEditTaskText(task.id)
                }
              }}
              onBlur={() => handleEditTaskText(task.id)} // Poistutaan muokkaustilasta
              autoFocus
            />
          </> : <>
            {/* Taskin nimi */}
            <span className={`project-note-task-title font-dm-regular project-note-task-width ${task.completed ? 'completed' : ''}`}
              onClick={() => {
                if (task.completed) return

                setEditingIndex(task.id);
                setEditingTask(task.title); // Asetetaan muokkaustilaan tehtävän otsikko
              }}>
              {task.title}
            </span>

            {/* Taskin pvm */}
            {task.date &&
              <span className={`project-note-task-date font-dm-regular clickable`}
                onClick={() => {
                  if (task.completed) return
                  setDateOpenTaskIndex(dateOpenTaskIndex === task.id ? null : task.id)
                }}
              >
                <DatePicker
                  open={dateOpenTaskIndex === task.id}
                  style={{ visibility: "hidden", width: 0, padding: 0, border: 0 }}
                  showWeek={true}
                  onChange={(
                    date
                  ) => {
                    handleEditTaskDate(date, task.id)
                  }}
                />
                {getParsedDate(task.date)}
              </span>
            }

            {/* Taskin henkilö */}
            {task.userName || userOpenTaskIndex === task.id ?
              <div className={`project-note-task-user font-dm-regular clickable`}
                onClick={() => {
                  if (userOpenTaskIndex === task.id) return
                  if (task.completed) return

                  setUserOpenTaskIndex(userOpenTaskIndex === task.id ? null : task.id)
                }}
              >
                <UserSelectDropdown taskId={task.id} value={task.userName} workersArr={workersArr} handleEditTaskUser={handleEditTaskUser} userOpenTaskIndex={userOpenTaskIndex} setUserOpenTaskIndex={setUserOpenTaskIndex} />

                <span className={`userName`}>
                  {task.userName}
                </span>

              </div> : <></>}



            {/* Task hoveri */}
            {taskHovered === task.id && !task.completed ? <div className="project-note-task-btns">

              {/* Käyttäjän valinta */}
              {!task.userName &&
                <div className="project-note-task-btn clickable" onClick={() => {
                  setUserOpenTaskIndex(task.id)
                }} >
                  <UserOutlined className="project-note-task-icon clickable" />
                </div>
              }


              {/* Päivämäärän valinta */}
              {!task.date &&
                <div className="project-note-task-btn clickable" onClick={() => {
                  setDateOpenTaskIndex(dateOpenTaskIndex === task.id ? null : task.id)
                }} >
                  <DatePicker
                    open={dateOpenTaskIndex === task.id}
                    style={{ visibility: "hidden", width: 0, padding: 0, border: 0 }}
                    showWeek={true}
                    onChange={(
                      date
                    ) => {
                      handleEditTaskDate(date, task.id)
                    }}
                  />

                  <CalendarOutlined className="project-note-task-icon clickable" />
                </div>
              }


              {/* Task poisto */}
              <div className="project-note-task-btn clickable" onClick={() => { deleteNoteTask(task.id) }} >
                <CloseOutlined className="project-note-task-icon clickable" />
              </div>

            </div> : <></>}
          </>}
        </div>

      </div>

    )
  }


  return (<>
    {showTasks ?
      <div className="project-note-tasks">
        <p className="project-note-tasks-header font-dm-bold">Toimenpiteet</p>

        <div className="project-note-tasks-items">
          {tasks.map((task, index) => (
            <RenderTaskRow task={task} />
          ))}

          {/* Uusi tehtäväkenttä */}
          <div className="project-note-task-new">
            <input
              maxLength={50}
              className="project-note-task-input-new project-note-task-width font-dm-regular"
              type="text"
              placeholder="Lisää toimenpide kirjoittamalla"
              value={newTask}
              onChange={(e) => setNewTask(e.target.value)}
              onKeyDown={(e) => {
                if (e.key === 'Enter') {
                  newNoteTask()
                }
              }}
              onBlur={newNoteTask} // Poistutaan muokkaustilasta, jos klikataan pois kentästä
            />
          </div>
        </div>

      </div>
      : <></>}
  </>)
}



const baseStyle = {
  borderRadius: 8,
  padding: '16px',
  backgroundColor: '#FFF',
  marginTop: '1em',

  borderColor: '#eeeeee',
  borderWidth: 0,
  borderStyle: 'solid',
  outline: 'none',
  transition: 'border .1s ease-in-out'
};

const focusedStyle = {
  borderWidth: 4,
  borderColor: '#2196f3'
};

const acceptStyle = {
  borderWidth: 4,
  borderColor: '#90abf5',
  backgroundColor: '#becdf7'
};

const rejectStyle = {
  borderWidth: 4,
  borderColor: '#ff1744'
};

const minSize = 1; // Vähintään 1 tavu (ei tyhjiä tiedostoja)
const maxSize = 10 * (1024 * 1024) // 10mb max file upload limit
const nameMaxLength = 50;

function nameLengthValidator(file) {
  console.log('file', file)

  if (file?.name?.length > nameMaxLength) {
    return {
      code: "name-too-large",
      message: `Name is larger than ${nameMaxLength} characters`
    };
  }

  return null
}



export const ProjectNoteFiles = ({ noteId, showFiles, setShowFiles }) => {
  const envConfig = getEnvSettings();
  const [isLoading, setisLoading] = useState(true);

  const [isFileModalOpen, setIsFileModalOpen] = useState(false);
  const [files, setFiles] = useState([]);

  const [fileData, setFileData] = useState({});



  const getNoteFiles = () => {
    setisLoading(true);

    let req_config = {
      method: "POST",
      url: `${envConfig.apiUrlPrefix}getProjectNoteFiles`,
      data: {
        noteId: noteId,
      },
    };
    console.log("UPP2", req_config);

    apiSecure(req_config)
      .then((response) => {
        let filesRes = response?.data?.data?.files
        setFiles(filesRes)
        if (filesRes?.length > 0) setShowFiles(true)
        setisLoading(false);
      })
      .catch((error) => {
        console.log("Error:", error);
      });
  };

  useEffect(() => {
    console.log('getNoteFiles')
    getNoteFiles()
  }, []);




  /* Tiedoston tallennus kutsu bäkkäriin */
  const uploadFileReq = (file) => {

    const formData = new FormData();
    formData.append("uploaded_file", file);
    formData.append("id", noteId);

    let req_config = {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "multipart/form-data",
      },
      url: `${envConfig.apiUrlPrefix}uploadProjectNoteFile`,
      data: formData,
    };

    apiSecure(req_config)
      .then((response) => {
        getNoteFiles();
      })
      .catch((error) => {
        console.log("Error:", error);
      });
  };

  const deleteFileReq = (fileid) => {
    console.log('')
    let req_config = {
      method: "POST",
      url: `${envConfig.apiUrlPrefix}deleteProjectNoteFile`,
      data: {
        fileid: fileid
      },
    };

    apiSecure(req_config)
      .then((response) => {
        getNoteFiles();
      })
      .catch((error) => {
        console.log("Error:", error);
      });
  };


  const onDrop = useCallback((acceptedFiles, fileRejections) => {
    acceptedFiles.forEach((file) => {
      uploadFileReq(file)
    })

    // Näytä ilmoitus liian suurista tiedostoista
    fileRejections.forEach(({ file, errors }) => {
      console.log('fileRejections', file, errors)

      errors.forEach((error) => {
        if (error.code === 'file-too-large') {
          notification.open({
            message: 'Tiedoston hylkäys',
            description: `${file.name} ylittää ${formatSizeInMB(maxSize)} maksimikoon.`,
            placement: 'top',
            type: 'error'
          });
        }

        if (error.code === 'file-too-small') {
          notification.open({
            message: 'Tiedoston hylkäys',
            description: `${file.name} tyhjiä tiedostoja ei voi lisätä.`,
            placement: 'top',
            type: 'error'
          });
        }

        if (error.code === 'too-many-files') {
          notification.open({
            message: 'Tiedoston hylkäys',
            description: `Maksimissaan 1 tiedosto kerrallaan!`,
            placement: 'top',
            type: 'error'
          });
        }

        if (error.code === 'name-too-large') {
          notification.open({
            message: 'Tiedoston hylkäys',
            description: `Tiedoston nimi ylittää ${nameMaxLength} merkkirajan.`,
            placement: 'top',
            type: 'error'
          });
        }


      });
    });

  }, [])

  const {
    getRootProps,
    getInputProps,
    isFocused,
    isDragAccept,
    isDragReject,
    open
  } = useDropzone({
    onDrop,
    minSize,
    maxSize,
    maxFiles: 1,
    validator: nameLengthValidator,
    noClick: true,
    noKeyboard: true
  })

  const style = useMemo(() => ({
    ...baseStyle,
    ...(isFocused ? focusedStyle : {}),
    ...(isDragAccept ? acceptStyle : {}),
    ...(isDragReject ? rejectStyle : {})
  }), [
    isFocused,
    isDragAccept,
    isDragReject
  ]);


  console.log('isFocused', isFocused)
  console.log('isDragAccept', isDragAccept)
  console.log('isDragReject', isDragReject)



  if (!showFiles) return <></>

  return (<>
    {true ?
      <div>


        <FileModal
          fileData={fileData}
          isModalOpen={isFileModalOpen}
          setIsModalOpen={setIsFileModalOpen}
        />


        <div {...getRootProps({ style })}>
          <div style={{ display: 'flex', flexDirection: 'row' }}>
            <p className="project-note-files-header font-dm-bold">Liitteet</p>


            <PlusOutlined onClick={open} style={{ fontSize: 10, marginLeft: '1em' }} />
          </div>

          {!isLoading ?
            <div className="project-note-files-items">
              {files.map((file) => (


                <Dropdown
                  key={file.LiiteNro}
                  menu={{
                    items: [
                      {
                        label: file.Tiedostonimi,
                        key: "name",
                        disabled: true,
                      },
                      {
                        label: "Poista tiedosto",
                        key: "delete",
                        danger: true,
                        onClick: () => {
                          console.log('file', file)
                          deleteFileReq(file.LiiteNro);
                        },
                      },
                    ],
                  }}
                  trigger={["contextMenu"]}
                >
                  <div className="project-note-files-item" onClick={() => {
                    setFileData({
                      url: 'getProjectNoteFile',
                      id: file.LiiteNro
                    });
                    setIsFileModalOpen(true);
                  }}>
                    <span className="font-dm-regular">{file.Tiedostonimi}</span>
                  </div>
                </Dropdown>




              ))}
            </div>

            : <div style={{ marginLeft: '1em', marginTop: '1em' }}>
              <LoadingItem color="#333" /> </div>}

          <input {...getInputProps()} />
        </div>


      </div>
      : <></>}
  </>)

}
