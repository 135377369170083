import { useCallback, useEffect, useState } from "react";
import { apiSecure } from "../../components/api";
import { Loading, LoadingItem } from "../../components/Loading/Loading";
import "./Contact.css";

import { RightOutlined, MenuOutlined, EditOutlined } from "@ant-design/icons";
import { Link, useNavigate, useParams } from "react-router-dom";
import { useTranslate } from "../../components/Translate";
import { useDispatch, useSelector } from "react-redux";
import config, { getEnvSettings } from "../../config/config";
import { AdvancedMarker, APIProvider, InfoWindow, Map, Marker, Pin, useAdvancedMarkerRef } from '@vis.gl/react-google-maps';
import { EditContactModal } from "../Project/ProjectInfo/EditContactModal";
import { BackBtn } from "../../components/BackBtn/BackBtn";
import { MapCustomBtns, RenderMapInfo } from "../../components/mapComponents";


function calculateCenter(coordinates) {
  if (coordinates.length === 0) return null;

  let totalLat = 0;
  let totalLng = 0;

  coordinates.forEach(coord => {
    const lat = parseFloat(coord.lat.replace(',', '.'));
    const lng = parseFloat(coord.lng.replace(',', '.'));
    totalLat += lat;
    totalLng += lng;
  });

  const centerLat = totalLat / coordinates.length;
  const centerLng = totalLng / coordinates.length;

  return {
    lat: centerLat,
    lng: centerLng
  };
}


export const Contact = ({ setToken }) => {
  const dispatch = useDispatch();
  const [isLoading, setisLoading] = useState(false);
  const { locale } = useTranslate();
  const navigate = useNavigate();
  const envConfig = getEnvSettings();

  let { id } = useParams();


  const [contact, setContact] = useState({});

  const API_KEY = process.env.REACT_APP_MAPS_API_KEY //config.devMapsKey


  const [isMapReady, setIsMapReady] = useState(false);
  const [coords, setCoords] = useState({ lat: 66.67, lng: 26.86 }); //Defaults to finland 

  const [isEditContactModalOpen, setIsEditContactModalOpen] = useState(false);
  const [editContactData, setEditContactData] = useState({});

  const [selectedMarker, setSelectedMarker] = useState(null);
  const [infoWindowShown, setInfoWindowShown] = useState(false);


  const [googleMapsLoaded, setGoogleMapsLoaded] = useState(false);


  // clicking a marker will set it as selected and show the infowindow
  const handleMarkerClick = useCallback((markerData) => {
    setSelectedMarker(markerData);
    setInfoWindowShown(true);
  }, []);

  // if the maps api closes the infowindow, we have to synchronize our state
  const handleCloseInfo = useCallback(() => {
    setInfoWindowShown(false);
    setSelectedMarker(null);
  }, []);


  useEffect(() => {
    if (!contact.YhteysNRO) return

    let projectsWithCoords = contact?.tyokohteet?.filter(itm => itm.lat && itm.lng)
    if (projectsWithCoords?.length >= 1) {
      const centerCoords = calculateCenter(projectsWithCoords);
      setCoords(centerCoords)
      setIsMapReady(true)
    }
  }, [contact]);

  useEffect(() => {
    console.log("ID muuttui:", id);


    setIsMapReady(false)
    setCoords({ lat: 66.67, lng: 26.86 })
  }, [id]); // Tämä suoritetaan aina, kun id muuttuu




  const getContact = () => {
    setisLoading(true);
    console.log("getContact");

    let req_config = {
      method: "POST",
      url: `${envConfig.apiUrlPrefix}getContact`,
      data: {
        contactId: id,
      },
    };

    apiSecure(req_config)
      .then((response) => {
        console.log("RESPONSE", response);

        setContact(response?.data?.data)
        setisLoading(false);
      })
      .catch((error) => {
        console.log("Error:", error);
        setisLoading(false);
      });
  };

  useEffect(() => {
    getContact();
  }, [id]);




  return (

    <div className="projectmain">


      <EditContactModal
        isOpen={isEditContactModalOpen}
        closeModal={() => {
          setIsEditContactModalOpen(false);
        }}
        projectId={null}
        data={contact}
        refreshData={getContact}
      />



      <div className={`projectpage-content`}>
        <BackBtn style={{ marginBottom: 5 }} onClick={() => {
          if (window.history.state && window.history.state.idx > 0) {
            navigate(-1);
          } else {
            navigate('/projects'); // Korvaa '/pääsivu' haluamallasi pääsivun URLilla
          }
        }} />



        {!isLoading ?


          <div className="project-info">
            <p className="project-info-company font-dm-regular">{contact?.Yritys}</p>
            <p className="project-info-title font-dm-bold">{contact?.Henkilo}</p>


            <div className="contact-box">
              <div className="contact-box-title-row ">
                <p className="contact-box-title font-dm-bold">{locale.contactinformation}</p>

                <div className="contact-box-title-edit clickable" onClick={() => {
                  console.log('contact', contact);
                  setEditContactData(contact)
                  setIsEditContactModalOpen(true);
                }}>
                  <EditOutlined />
                </div>

              </div>


              <div className="contact-box-row">

                <div className="contact-box-infos-left">

                  {contact?.Henkilo ?
                    <div className="contact-box-info">
                      <p className="contact-box-info-label font-dm-bold">{locale.name}:</p>
                      <p className="contact-box-info-value font-dm-regular">{contact?.Henkilo}</p>
                    </div>
                    : <></>}

                  {contact?.Yritys ?
                    <div className="contact-box-info">
                      <p className="contact-box-info-label font-dm-bold">{locale.company}:</p>
                      <p className="contact-box-info-value font-dm-regular">{contact?.Yritys}</p>
                    </div>
                    : <></>}

                  {contact?.GSM || contact?.Puhelin ?
                    <div className="contact-box-info">
                      <p className="contact-box-info-label font-dm-bold">{locale.phone}:</p>
                      <p className="contact-box-info-value font-dm-regular">{[contact?.GSM, contact?.Puhelin].filter(Boolean).join(", ")}</p>
                    </div>
                    : <></>}

                  {contact?.Sahkoposti ?
                    <div className="contact-box-info">
                      <p className="contact-box-info-label font-dm-bold">{locale.mail}:</p>
                      <p className="contact-box-info-value font-dm-regular">{contact?.Sahkoposti}</p>
                    </div>
                    : <></>}

                  {contact?.Lahiosoite || contact?.Postiosoite ?
                    <div className="contact-box-info">
                      <p className="contact-box-info-label font-dm-bold">{locale.address}:</p>
                      <p className="contact-box-info-value font-dm-regular">{[contact?.Lahiosoite, contact?.Postiosoite].filter(Boolean).join(", ")}</p>
                    </div>
                    : <></>}
                </div>

                {contact?.Muu_info ?
                  <div className="contact-box-infos-right">
                    <div className="contact-box-info">
                      <p className="contact-box-info-label2 font-dm-bold">Huomautuksia:</p>
                      <p className="contact-box-info-value font-dm-regular">{contact?.Muu_info}</p>
                    </div>
                  </div>
                  : <></>}

              </div>
            </div>

            {contact?.tyokohteet?.length >= 1 ?

              <div className="contact-box">
                <p className="contact-box-title font-dm-bold">{locale.worksites}</p>

                {contact.tyokohteet
                  ?.filter((item, index, self) =>
                    index === self.findIndex((t) => t.Tyokohde === item.Tyokohde)
                  )
                  .map((itm) => {
                    return <div style={{ display: 'flex' }}>
                      <Link to={`/project/${itm.Tyokohde}`} state={{ from: "/contact" }} key={itm.Tyokohde} className="contact-project-item">

                        <p className="contact-project-text font-dm-regular">{itm?.Tyokohde} {itm?.TyokohteenNimi}</p>

                        <p className="searchbar-results-project-status" style={{
                          color: itm?.Varikoodi,
                          borderColor: itm?.Varikoodi
                        }}>{itm?.TilaNimi}</p>

                      </Link>
                    </div>
                  })
                }

                {isMapReady ?
                  <div className="contact-projects-map">
                    <APIProvider apiKey={API_KEY} onLoad={() => {
                      setGoogleMapsLoaded(true)
                    }}>
                      <Map
                        id="map-custom-id"
                        mapId={"a5c9eab71d5a0001"}
                        style={{ width: "100%", height: "100%", borderRadius: "14px" }}
                        defaultCenter={{ lat: coords.lat, lng: coords.lng }}
                        defaultZoom={5}
                        gestureHandling={"greedy"}
                        disableDefaultUI={true}
                      /* fullscreenControl={false} */
                      >

                        <MapCustomBtns id={"map-custom-id"} />

                        {contact?.karttaTehtaat?.map((item) => {
                          var newlat = parseFloat(item.lat.replace(/,/g, "."));
                          var newlng = parseFloat(item.lng.replace(/,/g, "."));
                          return (
                            <AdvancedMarker
                              key={item.Id}
                              position={{ lat: newlat, lng: newlng }}
                              zIndex={999999}
                            >
                              <img
                                src={item?.IkoniUrl}
                                style={{ width: '30px', height: '30px' }}
                              />
                            </AdvancedMarker>
                            /*                       
                            <Marker
                              key={item.Id}
                              position={{ lat: newlat, lng: newlng }}
                              icon={{
                                url: item?.IkoniUrl,
                                scaledSize: window.google ? new window.google.maps.Size(30, 30) : null
                              }}
                            /> */
                          )
                        })}

                        {contact?.tyokohteet
                          ?.filter((item, index, self) =>
                            index === self.findIndex((t) => t.Tyokohde === item.Tyokohde)
                          )
                          .map((item) => {
                            if (!item.lat || !item.lng) return null;
                            var newlat = parseFloat(item.lat.replace(/,/g, "."));
                            var newlng = parseFloat(item.lng.replace(/,/g, "."));
                      /*       console.log("item", item, newlat, newlng); */
                            return (
                              <AdvancedMarker
                                key={item.Tyokohde}
                                position={{ lat: newlat, lng: newlng }}
                                onClick={() => handleMarkerClick({
                                  isProject: true,
                                  name: item.TyokohteenNimi,
                                  id: item.Tyokohde,
                                  status: item?.TilaNimi,
                                  lat: newlat,
                                  lng: newlng,
                                })}
                              >
                                <Pin
                                  background={item?.Varikoodi}
                                  borderColor={"#333"}
                                  glyphColor={item?.Varikoodi}
                                  scale={0.8}
                                />
                              </AdvancedMarker>
                            );
                          })}


                        <RenderMapInfo infoWindowShown={infoWindowShown} selectedMarker={selectedMarker} handleClose={handleCloseInfo} />
                      </Map>
                    </APIProvider>
                  </div>
                  : null}

              </div>
              : <></>}
          </div>


          : <div className="project-info">    <div className="project-info-loading">
            <LoadingItem color="#000" height={'2vh'} width={'2vh'} />
          </div></div>}

      </div>
    </div>

  );
};
