import { CloseOutlined } from "@ant-design/icons";
import { useEffect, useState } from "react";

import { Input, Modal, Button } from "antd";
import dayjs from "dayjs";
import { useTranslate } from "../../../components/Translate";




export const EditReceiverModal = ({ isOpen, closeModal, data, saveFunc }) => {
  //console.log("EditReceiverModal data", data);
  const [receiver, setReceiver] = useState(null);

  const { locale } = useTranslate();

  useEffect(() => {
    if (isOpen) {
      setReceiver(data.receiver)
    } else {
      setReceiver(null)
    }

  }, [isOpen]);

  return (
    <>
      <Modal
        open={isOpen}
        onCancel={() => closeModal()}
        title={`Muokkaa vastaanottajaa`}
        centered={true}
        footer={[
          <Button key="submit" type="primary" color="red" onClick={() => {
            closeModal()
            saveFunc(receiver)
          }}
          >
            {locale.save}
          </Button>,
        ]}
      >
        <div>
          <p className="project-modal-label font-dm-medium">{"Vastaanottaja"}</p>
          <Input
            maxLength={50}
            value={receiver}
            onChange={(e) => {
              setReceiver(e.target.value)
              console.log("onChange", e.target.value)
            }}
          />
        </div>
      </Modal>
    </>
  );
};
