
import { useEffect, useState } from "react";


import { DatePicker, Input } from "antd";
import 'moment/locale/fi';
import { apiSecure } from "../../../components/api";
import { Loading } from './../../../components/Loading/Loading';
import dayjs from "dayjs";



const { RangePicker } = DatePicker;

export const Screen4 = ({
  setHeaderText,
  closeModal,
  data,
  installWorks,
  setScreen,
  SaveBtn,
}) => {
  const [work, setWork] = useState({
    id: null,
    name: null,
    chosed: false
  });
  const [isLoading, setisLoading] = useState(false);
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);

  const [text, setText] = useState('');

  useEffect(() => {
    setHeaderText("Lisää asennus")
  }, [setHeaderText])


  const saveData1 = () => {
  
      if (!startDate) return alert("Valitse aloituspäivämäärä");
      if (!endDate) return alert("Valitse lopetuspäivämäärä");
      if (!text) return alert("Lisää selite");

 
    setisLoading(true);

    let req_config = {
      method: "POST",
      url: `/intra_newInstall`,
      data: {
        worker: data.userId,
        work: work.id,
        startDate: dayjs(startDate).format("YYYY-MM-DD"),
        endDate: dayjs(endDate).format("YYYY-MM-DD"),
        text: text,
      },
    };

    console.log(req_config);

    apiSecure(req_config)
      .then((response) => {
        console.log("RESPONSE", response.data);
        //setUsersData(response.data.data);
        setisLoading(false);
        closeModal(true)
      })
      .catch((error) => {
        console.log("Error:", error);
        setisLoading(false);
      }); 
  };

  if (isLoading) {
    return <Loading />;
  }


  return (
    <>

      {!work.chosed && (
        <div className="worksData">
          {installWorks.map((work, index) => {
            console.log("work", work);

            return (
              <div key={index}>
                <div
                  className="screen1WorkCont"
                  onClick={() => {
                    setWork({
                      id: work.Tyokohdenro,
                      name: work.TyokohdeNimi,
                      chosed: true
                    });
                  }}
                >
                  <p className="name font-dm-bold">{work.TyokohdeNimi}</p>
                </div>
              </div>
            );
          })}
        </div>
      )}

      {work.chosed && (
        <>
          <div className="screen3Cont">
            <div>
              <p className="header1 font-dm-bold">Asennuksen tiedot</p>
            </div>

            <div className="screen3Row">
              <p className="font-dm-medium label">Työkohde</p>
              <p className="font-dm-bold value">{(work.id ? work.id : '') + " " + work.name}</p>
            </div>

              <div className="screen3Row">
                <p className="font-dm-medium label">Aloitus / Lopetus</p>


                {/* <p className="font-dm-medium value">20.9. - 20.10.2022</p> */}
                <RangePicker
                  className="rangepicker"
                  size="small"
                  showWeek={true}
                  suffixIcon={null}
                  value={[startDate, endDate]}
                  onChange={(value) => {
                    setStartDate(value[0]);
                    setEndDate(value[1]);
                  }}
                  onOk={() => {
                    console.log("onOk");
                  }}
                />
              </div>

              <div className="screen3Row">
              <p className="font-dm-medium label">Selite</p>

              <Input
              className="screen4Input"
              value={text}
              onChange={(text) => {
                setText(text.target.value)
                console.log(text.target.value)
              }}
              />

            </div>
          </div>

          <SaveBtn onClick={saveData1} />
        </>
      )}

      {!work.chosed && (
        
        <div className="footerButtonCont">
                    <button
            className="footerButton font-dm-regular"
            onClick={() =>                     setWork({
              id: null,
              name: 'Ei valittu',
              chosed: true
            })}
          >
            Lisää ilman kohdetta
          </button>
          <button
            className="footerButton font-dm-regular"
            onClick={() => setScreen(3)}
          >
            Lisää poissaolo
          </button>
          <button
            className="footerButton font-dm-regular"
            onClick={() => setScreen(5)}
          >
            Lisää muu merkintä
          </button>
        </div>
      )}
    </>
  );
};
