import { CloseOutlined } from "@ant-design/icons";
import { useEffect, useState } from "react";

import { Input, notification, Modal, Button } from "antd";
import dayjs from "dayjs";
import { useTranslate } from "../../../components/Translate";
import { apiSecure } from "../../../components/api";
import { getEnvSettings } from "../../../config/config";
import { LockOutlined, UnlockOutlined } from '@ant-design/icons';
import { useNavigate } from "react-router-dom";

const { TextArea } = Input;


const ModalTextItem = ({ label, value, setValue, disabled = false, canLock = false, locked, setLocked, status = "" }) => {
  const { locale } = useTranslate();

  return (<div>
    <p className="project-modal-label font-dm-medium">{label}</p>
    <Input
      value={value}
      disabled={locked || disabled}
      onChange={(e) => {
        setValue(e.target.value)
        console.log("onChange", e.target.value)
      }}
      status={status}
      addonAfter={canLock ? <>{locked ? <LockOutlined onClick={() => { setLocked(false) }} /> : <UnlockOutlined onClick={() => { setLocked(true) }} />}</> : null}
    />
  </div>
  );
};

const ModalTextAreaItem = ({ label, value, setValue, disabled = false }) => {
  const { locale } = useTranslate();

  return (<div>
    <p className="project-modal-label font-dm-medium">{label}</p>
    <TextArea rows={4} value={value}
      disabled={disabled}
      onChange={(e) => {
        setValue(e.target.value)
        console.log("onChange", e.target.value)
      }} />
  </div>
  );
};

const formatNull = (data) => {
  return data ? data : null
};

export const AddContactModal = ({ isOpen, closeModal, projectId, refreshData }) => {
  const [isLoading, setIsLoading] = useState(false);

  const [role, setRole] = useState(null);
  const [name, setName] = useState(null);
  const [company, setCompany] = useState(null);
  const [phone1, setPhone1] = useState(null);
  const [phone2, setPhone2] = useState(null);
  const [mail, setMail] = useState(null);
  const [address1, setAddress1] = useState(null);
  const [address2, setAddress2] = useState(null);
  const [otherInfo, setOtherInfo] = useState(null);

  const [nameLocked, setNameLocked] = useState(true);
  const [companyLocked, setCompanyLocked] = useState(true);

  const { locale } = useTranslate();
  const envConfig = getEnvSettings();

  const navigate = useNavigate();

  useEffect(() => {
    if (isOpen) {
      setRole(null)
      setName(null)
      setCompany(null)
      setPhone1(null)
      setPhone2(null)
      setMail(null)
      setAddress1(null)
      setAddress2(null)
      setOtherInfo(null)
      setNameLocked(true)
      setCompanyLocked(true)
    }
  }, [isOpen]);


  const saveData = () => {
    if (!name && !company) {
      notification.open({
        message: `Nimi tai yritys puuttuu!`,
        placement: 'top',
        type: 'error'
      });
      return
    }

    setIsLoading(true)

    let req_config = {
      method: "POST",
      url: `${envConfig.apiUrlPrefix}addContact`,
      data: {
        projectId: projectId,
        data: {
          role: formatNull(role),
          name: formatNull(name),
          company: formatNull(company),
          phone1: formatNull(phone1),
          phone2: formatNull(phone2),
          mail: formatNull(mail),
          address1: formatNull(address1),
          address2: formatNull(address2),
          otherInfo: formatNull(otherInfo),
        },
      },
    };
    console.log("addContact", req_config);

    apiSecure(req_config)
      .then((response) => {
        console.log("RESPONSE", response.data);

        if (response?.data?.status) {
          notification.open({
            message: "Yhteystieto lisätty!",
            placement: 'top',
            type: 'success'
          });


          //JOs ei ole määritelty projectid: avataan yhteystieto. Muuten päivitetään projektin tiedot
          if (!projectId) {
            let nContactId = response?.data?.contactId
            if (nContactId) {
              navigate(`/contact/${nContactId}`);
            }
          } else {
            refreshData()
          }

        } else {
          notification.open({
            message: "Yhteystiedon lisäys epäonnistui!",
            placement: 'top',
            type: 'error'
          });
        }

        closeModal()
        setIsLoading(false)
      })
      .catch((error) => {
        console.log("Error:", error);
      });

  }

  return (
    <>
      <Modal
        open={isOpen}
        onCancel={() => closeModal()}
        title={`Lisää yhteystieto`}
        centered={true}
        footer={[
          <Button key="submit" type="primary" color="red" onClick={() => {
            saveData()
          }}
            loading={isLoading}>
            Tallenna
          </Button>,
        ]}
      >
        <div >
          {projectId ?
            <ModalTextItem
              value={role}
              setValue={setRole}
              label={"Rooli"}
              disabled={false}
            />
            : <></>}

          <ModalTextItem
            value={name}
            setValue={setName}
            label={"Nimi"}
            status={name || company ? "" : "error"}
          />

          <ModalTextItem
            value={company}
            setValue={setCompany}
            label={"Yritys"}
            status={name || company ? "" : "error"}
          />

          <ModalTextItem
            value={phone1}
            setValue={setPhone1}
            label={"Puhelin"}
          />
          <ModalTextItem
            value={phone2}
            setValue={setPhone2}
            label={""}
          />

          <ModalTextItem
            value={mail}
            setValue={setMail}
            label={"Sähköposti"}
          />

          <ModalTextItem
            value={address1}
            setValue={setAddress1}
            label={"Osoite"}
          />

          <ModalTextItem
            value={address2}
            setValue={setAddress2}
            label={""}
          />

          <ModalTextAreaItem
            value={otherInfo}
            setValue={setOtherInfo}
            label={"Huomautuksia"}
          />

        </div>
      </Modal>
    </>
  );
};
