import { CloseOutlined } from "@ant-design/icons";
import { useEffect, useState } from "react";
import { Input, Modal, Button } from "antd";

import dayjs from "dayjs";
import { useTranslate } from "../../../components/Translate";


export const EditScheduleModal = ({ isOpen, closeModal, data, saveFunc }) => {
  //console.log("EditScheduleModal data", data);
  const [schedule, setSchedule] = useState(null);

  const { locale } = useTranslate();

  useEffect(() => {
    if (isOpen) {
      setSchedule(data.schedule)
    } else {
      setSchedule(null)
    }

  }, [isOpen]);

  return (
    <>
      <Modal
        open={isOpen}
        onCancel={() => closeModal()}
        title={`Muokkaa aikataulua`}
        centered={true}
        footer={[
          <Button key="submit" type="primary" color="red" onClick={() => {
            closeModal()
            saveFunc(schedule)
          }}
          >
            {locale.save}
          </Button>,
        ]}
      >
        <div>
          <p className="project-modal-label font-dm-medium">{"Aikataulu"}</p>
          <Input
            maxLength={255}
            value={schedule}
            onChange={(e) => {
              setSchedule(e.target.value)
              console.log("onChange", e.target.value)
            }}
          />
        </div>
      </Modal>
    </>
  );
};
