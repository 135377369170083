import { CloseOutlined } from "@ant-design/icons";
import { useEffect, useState } from "react";
import "./AddWorkModal.css";
import { Screen1 } from "./screen1";
import { Screen2 } from './screen2';
import { Screen2Edit } from './screen2edit';
import { Screen3 } from './screen3';
import { Screen4 } from './screen4';
import { Screen3edit } from "./screen3edit";
import { Screen5 } from "./screen5";
import { Screen5edit } from "./screen5edit";
import { Modal } from "antd";

const SaveBtn = ({ onClick, text = 'Tallenna' }) => {
  return (
    <div className="saveButtonCont">
      <button className="saveButton font-dm-regular" onClick={onClick}>
        {text}
      </button>
    </div>
  );
};

const DeleteBtn = ({ onClick, text = 'Poista' }) => {
  return (
    <div className="deleteButtonCont">
      <button className="deleteButton font-dm-regular" onClick={onClick}>
        {text}
      </button>
    </div>
  );
};

export const AddWorkModal = ({ tables, elements, installWorks, data, isOpen, closeModal }) => {
  const [screen, setScreen] = useState(1);
  const [worksData, setWorksData] = useState([]);
  const [selectedWork, setSelectedWork] = useState({});

  const [headerText, setHeaderText] = useState("");
  const [modalFooter, setModalFooter] = useState([]);
  console.log("AddWorkModal data", data);

  useEffect(() => {
    setScreen(1)
    console.log("modaldata:_", data)

    if (data.poissaoloNro) {
      setScreen(3.1)
    }

    if (data.stockWorker) {
      setScreen(3);
    }
    if (data.editRow) {
      setScreen(2.1);
      setSelectedWork(data.SelectedWork)
    }

    if (data.installWorker) {
      console.log('setScreen4')
      setScreen(4);
    }

    if (data.otherdateId) {
      setScreen(5.1);
    }
  }, [isOpen]);

  useEffect(() => {
    console.log("elements updated", elements);
    let newArray = [];

    if (elements) {
      elements.map((row, index) => {
        let arrayId = "id" + row.Tyokohdenro;

        if (!newArray[arrayId]) {
          newArray[arrayId] = {
            id: row.Tyokohdenro,
            name: row.Tyokohde,
            elements: [],
            open: false,
          };
        }

        newArray[arrayId].elements.push(row);
      });

      console.log('AllWorks', newArray);
      setWorksData(newArray);
    }
  }, [elements]);


  return (
    <>
      <Modal
        open={isOpen}
        onCancel={() => closeModal()}
        centered={true}
        title={<>
          <p className="modalHeader font-dm-bold">{headerText}</p>
          {data?.userName && <p className="modalHeaderUser font-dm-medium">{data.userName}</p>}
        </>}
        footer={modalFooter}
      >
        {/*         <div className="modalContainer">
          <div className="modal" style={{ maxHeight: screen == 2 || screen == 2.1 ? "1000px" : "800px" }}> */}
        <div>
          {/* Tuotannon kuormitus valinta */}
          {screen == 1 && (
            <Screen1 setHeaderText={setHeaderText} closeModal={closeModal} data={data} worksData={worksData} setWorksData={setWorksData} setSelectedWork={setSelectedWork} setScreen={setScreen} />
          )}

          {/* Tuotannon kuormitus tarkempi */}
          {screen == 2 && (
            <Screen2 setHeaderText={setHeaderText} closeModal={closeModal} tables={tables} data={data} selectedWork={selectedWork} SaveBtn={SaveBtn} />
          )}

          {/* Tuotannon kuormitus muokkaus */}
          {screen == 2.1 && (
            <Screen2Edit setHeaderText={setHeaderText} closeModal={closeModal} tables={tables} data={data} selectedWork={selectedWork} SaveBtn={SaveBtn} DeleteBtn={DeleteBtn} />
          )}

          {/* Poissaolon lisääminen */}
          {screen == 3 && (
            <Screen3 setHeaderText={setHeaderText} closeModal={closeModal} data={data} selectedWork={selectedWork} setScreen={setScreen} SaveBtn={SaveBtn} />
          )}

          {/* Poissaolon Muokkaaminen */}
          {screen == 3.1 && (
            <Screen3edit setHeaderText={setHeaderText} closeModal={closeModal} data={data} selectedWork={selectedWork} SaveBtn={SaveBtn} DeleteBtn={DeleteBtn} />
          )}

          {/* Asennus tyokohde valinta */}
          {screen == 4 && (
            <Screen4 setHeaderText={setHeaderText} closeModal={closeModal} data={data} installWorks={installWorks} setSelectedWork={setSelectedWork} setScreen={setScreen} SaveBtn={SaveBtn} />
          )}

          {/* Asennus tyokohde valinta */}
          {screen == 5 && (
            <Screen5 setHeaderText={setHeaderText} closeModal={closeModal} data={data} selectedWork={selectedWork} setScreen={setScreen} SaveBtn={SaveBtn} />
          )}
          {screen == 5.1 && (
            <Screen5edit setHeaderText={setHeaderText} closeModal={closeModal} data={data} selectedWork={selectedWork} setScreen={setScreen} SaveBtn={SaveBtn} DeleteBtn={DeleteBtn} />
          )}

        </div>
      </Modal>
    </>
  );
};
