import { CloseOutlined } from "@ant-design/icons";
import { useEffect, useState } from "react";

import { Button, DatePicker, Input, Modal, Select } from "antd";
import dayjs from "dayjs";
import { apiSecure } from "../../../components/api";
import { getEnvSettings } from "../../../config/config";
import { useTranslate } from "../../../components/Translate";

const { RangePicker } = DatePicker;
const { Option } = Select;

export const NewStage = ({ isOpen, closeModal, stages, projectId,getProject }) => {
  const envConfig = getEnvSettings();

  const [isLoading, setIsLoading] = useState(false);

  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [baseStage, setBaseStage] = useState('base');
  const [name, setName] = useState('');

  const { locale } = useTranslate();

  useEffect(() => {

    if (isOpen) {
      setStartDate(null)
      setEndDate(null)
      setBaseStage('base')
    }
  }, [isOpen]);


  const addNewStage = () => {
    setIsLoading(true)

    let req_config = {
      method: "POST",
      url: `${envConfig.apiUrlPrefix}newStage`,
      data: {
        projectId: projectId,
        stage: baseStage,
        name: name,
        start: startDate ? dayjs(startDate).format("YYYY-MM-DD") : null,
        end:  endDate ? dayjs(endDate).format("YYYY-MM-DD"):null,
      },
    };

    apiSecure(req_config)
      .then((response) => {
        closeModal();
        getProject();
        setIsLoading(false)
      })
      .catch((error) => {
        console.log("Error:", error);
      });
  };




  return (
    <>
      <Modal
        open={isOpen}
        onCancel={() => closeModal()}
        title={locale.etapadd}
        centered={true}
        footer={[
          <Button key="submit" type="primary" color="red" onClick={() => {
            addNewStage()
          }}
          loading={isLoading}
          >
            Tallenna
          </Button>,
        ]}
      >
        <div>
          <p className="project-modal-label font-dm-medium">{locale.etapbase}:</p>
          <Select
            defaultValue="0"
            style={{ width: 150 }}
            className="picker value"
            value={baseStage}
            onChange={(value, option) => {
              console.log("PICKER CHANGE", value);
              setBaseStage(value)
            }}
          >
            <Option value={'base'}>{locale.etapbase}</Option>

            {stages.map((item, index) => {
              if (item.ParentID != 0) return;
              return (
                <Option key={index + 1} value={item.EtappiNro}>
                  {item.Etappi}
                </Option>
              );
            })}
          </Select>

          <p className="project-modal-label font-dm-medium">{locale.etapeditname}</p>

          <Input
            value={name}
            onChange={(text) => {
              setName(text.target.value)
              console.log(text.target.value)
            }}
          />


          <p className="project-modal-label font-dm-medium">{locale.etapeditdate}</p>

          <RangePicker
            className="rangepicker"
            size="small"
            showWeek={true}
            suffixIcon={null}
            value={[startDate, endDate]}
            format={'DD.MM.YYYY'}
            onChange={(value) => {
              //console.log("onChange", value, dayjs(value[0]).format("YYYY-MM-DD"), dayjs(value[1]).format("YYYY-MM-DD"));
              setStartDate(value[0]);
              setEndDate(value[1]);
            }}
            onOk={() => {
              console.log("onOk");
            }}
          />
        </div>
      </Modal>
    </>
  );
};
