import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { Loading } from "../../components/Loading/Loading";
import "./Schedule.css";

import Timeline, {
  DateHeader,
  SidebarHeader,
  TimelineHeaders
} from "react-calendar-timeline";

// make sure you include the timeline stylesheet or the timeline will not be styled
import moment from "moment";
import "moment/locale/fi";
import "react-calendar-timeline/lib/Timeline.css";
import { getParsedDate } from "../../components/globalFunctions";
import { drawLine, DrawTooltip } from "./ScheduleSharedFunc";


import {
  DownOutlined, UpOutlined
} from "@ant-design/icons";

export const ProjectsView = ({
  usersData,
  getUsersData,
  groupsOpen,
  setGroupsOpen,
  handleBtnOpenAll,
  btnAllOpen,
  userLines,
  installLines,
}) => {
  const DAY = 1000 * 60 * 60 * 24;
  const WEEK = DAY * 7;
  const MONTH = WEEK * 4;

  const MIN_ZOOM = WEEK * 3;
  const MAX_ZOOM = MONTH * 6;

  //const [usersData, setUsersData] = useState([]);
  const [groupsData, setGroupsData] = useState([]);
  const [itemsData, setItemsData] = useState([]);



  //Tables to Timeline
  useEffect(() => {
    if (usersData.tyontekijasisalto || usersData.lomat) {
      let newGroups = [];
      let newItems = [];

      let dataprojektit = [...usersData.projektit].sort(
        (a, b) => a.Tyokohdenro - b.Tyokohdenro
      );

      let dataetapit = [...usersData.projektit].sort(
        (a, b) => a.EtappiNro - b.EtappiNro
      );





      //Luodaan projektit ja käyttäjät niiden alle
      if (dataprojektit) {
        //console.log("dataprojektit", dataprojektit);

        dataprojektit.map((project, index) => {
          if (!project.Tyokohdenro) return;

          let projectGroupId = "project_" + project.Tyokohdenro;

          //Tarkistetaan löytyykö jo
          if (newGroups.some((project) => project.id === projectGroupId))
            return;

          //Luodaan projekti
          newGroups.push({
            id: projectGroupId,
            title: project.Tyokohde,
            isProject: true,
            projectId: project.Tyokohdenro,
            loadPercent: project.Kuormitusprosentti,
            planPercent: project.Suunnitelmaprosentti,
            height: 80,
          });

          if (project.Kuormitusprosentti <= 0) return;

          //Projektin kokonaiskuva viivaa varten
          let firstStart = moment();
          let lastEnd = moment();

          let lastLineEnd = {
            user: null,
            date: null,
          };

          Object.keys(userLines).map((userid, index) => {
            let userdata = userLines[userid];
            let userlines = userLines[userid].data;

            userlines.map((row, index2) => {
              if (project.Tyokohdenro != row.project) return;
              //if (project.Tyokohdenro != row.Tyokohdenro) return;

              let userGroupId = projectGroupId + "_user_" + userdata.id;

              if (!newGroups.some((person) => person.id === userGroupId)) {
                newGroups.push({
                  id: userGroupId,
                  title: userdata.name,
                });
              }

              drawLine(
                newItems,
                userGroupId,
                row.title,
                row.start,
                row.end,
                row.type,
                row.data
              );

              //Projektin aloitus ja lopetus viivojen määrittely
              if (row.start < firstStart) firstStart = row.start;
              if (row.end > lastEnd) lastEnd = row.end;
            });
          });

          Object.keys(installLines).map((userid, index) => {
            let userdata = installLines[userid];
            let installlines = installLines[userid].data;

            installlines.map((row, index2) => {
              //console.log("test5", row);
              if (project.Tyokohdenro != row.project) return;
              //if (project.Tyokohdenro != row.Tyokohdenro) return;

              let userGroupId = projectGroupId + "_user_" + userdata.id;

              if (!newGroups.some((person) => person.id === userGroupId)) {
                newGroups.push({
                  id: userGroupId,
                  title: userdata.name,
                });
              }

              drawLine(
                newItems,
                userGroupId,
                row.title,
                row.start,
                row.end,
                row.type,
                row.data
              );

              //Projektin aloitus ja lopetus viivojen määrittely
              if (row.start < firstStart) firstStart = row.start;
              if (row.end > lastEnd) lastEnd = row.end;
            });
          });

          //console.log("firstStart", firstStart);
          //console.log("lastEnd", lastEnd);

          //Projektin päättymispvm viiva
          let projectEndDate = moment(project.ToimituksenErapaiva); //moment().add(10, "days"); //project.ToimituksenErapaiva

          //Luodaan projektille viiva
          if (lastEnd > projectEndDate) {
            drawLine(
              newItems,
              projectGroupId,
              " ",
              firstStart,
              projectEndDate,
              "project",
              {
                data: {
                  name: project.Tyokohde,
                  estimatedEnd: getParsedDate(lastEnd),
                },
              }
            );

            drawLine(
              newItems,
              projectGroupId,
              " ",
              moment(projectEndDate).add(1, "days"),
              lastEnd,
              "project-ended",
              {
                data: {
                  name: project.Tyokohde,
                  estimatedEnd: getParsedDate(lastEnd),
                },
              }
            );
          } else {
            //Luodaan projektille viiva
            drawLine(
              newItems,
              projectGroupId,
              " ",
              firstStart,
              lastEnd,
              "project",
              {
                data: {
                  name: project.Tyokohde,
                  estimatedEnd: getParsedDate(lastEnd),
                },
              }
            );
          }

          //Porjektin lopetus viiva
          drawLine(
            newItems,
            projectGroupId,
            " ",
            moment(projectEndDate).hours(23).minutes(30).seconds(0),
            moment(projectEndDate).hours(24).minutes(0).seconds(0),
            "project-end",
            {},
            false
          );

          //Jos projektia ei ole "avattu", piilotetaan henkilöt
          if (!groupsOpen[projectGroupId]) {
            //console.log("ASD");
            newGroups = newGroups.filter(
              (obj) => obj.id.includes(projectGroupId + "_user") == false
            );
/*             newItems = newItems.filter(
              (obj) => obj.group.includes(projectGroupId + "_user") == false
            ); */
          }
        });

        //Etapit
        dataetapit.map((stage, index) => {
          if (!stage.EtappiNro) return;
          let projectGroupId = "project_" + stage.Tyokohdenro;
          //console.log('stage', stage)

          drawLine(
            newItems,
            projectGroupId,
            stage.Etappi,
            stage.ArvioituToimituksenAlku, //moment().add(getRandomArbitrary(5,10), 'days'),  //stage.ArvioituToimituksenAlku,
            stage.ArvioituToimituksenLoppu, //moment().add(getRandomArbitrary(10,15), 'days'),  //stage.ArvioituToimituksenLoppu,
            "stage",
            {}
          );
        });
      }

      //console.log("newGroups", newGroups);
      console.log("newItems", newItems);

      setGroupsData(newGroups);
      setItemsData(newItems);
    }
  }, [usersData, groupsOpen]);

  const groupRenderer = ({ group }) => {
    //let projectGroupId = "project_" + project.Tyokohdenro;
    //groupsOpen[projectGroupId]

    console.log('groupRenderer', group)

    return (
      <>
        {group.isProject ? (
          <div
            className="project-group"
            onClick={() => {
              //console.log("click group", group);

              let OpenList = groupsOpen;
              let IsOpen = OpenList[group.id];
              OpenList[group.id] = !IsOpen;

              //setGroupsData([]);

              //Odotetaan 200ms ennenkuin lasketaan/näytetään uudet viivat -> ei näyttö välähtele niin paljon
              //setTimeout(() => {
              setGroupsOpen({
                ...OpenList,
              });
              //}, 200);
            }}
          >
            <p className={`title project font-dm-bold`}>{group.projectId} {group.title}</p>

            <div className="row2 font-dm-regular">
     {/*          <p className="rowId">{group.projectId}</p> */}
              <p className="row2Item1">
                Kuormitettu {group.loadPercent && group.loadPercent.toFixed(0)}%
              </p>
              <p className="rowDivider">|</p>
              <p
                className={`row2Item2  ${
                  group.loadPercent > group.planPercent ? "red" : ""
                }`}
              >
                Suunnitelmia {group.planPercent}%
              </p>
            </div>

            {/* <p className="tip">{group.tip}</p> */}
          </div>
        ) : (
          <div className="custom-group">
            <span className={`title font-dm-medium`}>{group.title}</span>
          </div>
        )}
      </>
    );
  };

  const itemRenderer = ({
    item,
    timelineContext,
    itemContext,
    getItemProps,
    getResizeProps,
  }) => {
    let itemProps = getItemProps({});
    //console.log("getItemProps:", itemProps.style);

    //Suoritetaan laskennat/muutokset korkeutta/ryhmittelyä varten

    if (
      item.type == "project" ||
      item.type == "project-end" ||
      item.type == "project-ended" ||
      item.type == "stage"
    ) {
      var top = parseFloat(itemProps.style.top.slice(0, -2));
      top = top + 35;
      itemProps.style.top = top + "px";
    }

    if (item.type == "stage") {
      //console.log("stage getItemProps:", itemProps.style);
      var top = parseFloat(itemProps.style.top.slice(0, -2));
      var height = parseFloat(itemProps.style.height.slice(0, -2));

      height = 20;
      top = top + 5;

      itemProps.style.top = top + "px";
      itemProps.style.height = height + "px";
      itemProps.style.lineHeight = height + "px";
    }

    if (item.type == "project-end") {
      var top = parseFloat(itemProps.style.top.slice(0, -2));
      var height = parseFloat(itemProps.style.height.slice(0, -2));

      height = 50;
      top = top - 10;

      itemProps.style.top = top + "px";
      itemProps.style.height = height + "px";
      itemProps.style.lineHeight = height + "px";
    }

    let tooltipTypes = {
      work: true,
      project: true,
      "project-ended": true,
      install: true,
      install_proj: true,
    };

    let showTooltip = tooltipTypes[item.type];

    return (
      <>
        <div
          data-tip
          data-for={"tooltip-" + item.id}
          {...itemProps}
          onMouseDown={() => {
            //console.log("on item click", item);
          }}
        >
          <div
            style={{
              height: itemContext.dimensions.height,
              paddingLeft: 5,
              paddingRight: 3,
              borderRadius: 10,
            }}
          >
            <p
              style={{
                margin: 0,
                whiteSpace: "nowrap",
                overflow: "hidden",
                textOverflow: "ellipsis",
              }}
              className="font-dm-regular"
            >
              {itemContext.title}
            </p>
          </div>
        </div>

        {showTooltip && (
          <DrawTooltip
            id={"tooltip-" + item.id}
            type={item.type}
            data={item.data}
          />
        )}
      </>
    );
  };

  return (
    <>
      <div className="tl-container">
        {groupsData && groupsData.length > 0 ? (
          <div id={`capture-projektit`}>
            <Timeline /* resizeDetector={containerResizeDetector} */
              groups={groupsData}
              items={itemsData}
              defaultTimeStart={moment().hours(0)}
              defaultTimeEnd={moment().hours(0).add(4, "week")}
              /*               visibleTimeStart={moment().hours(0)}
              visibleTimeEnd={moment().hours(0).add(1, "year")} */
              canMove={false}
              canResize={false}
              canChangeGroup={false}
              /*   stackItems  */
              timeSteps={{
                second: 1,
                minute: 1,
                hour: 1,
                day: 1,
                month: 1,
                year: 1,
              }}
              sidebarWidth={300}
              lineHeight={50}
              itemRenderer={itemRenderer}
              groupRenderer={groupRenderer}
              /* horizontalLineClassNamesForGroup={(group) => ["custom-group"]} */
              minZoom={MIN_ZOOM}
              maxZoom={MAX_ZOOM}
              onItemSelect={null}
              onItemClick={null}
            >
              <TimelineHeaders>
                <SidebarHeader>
                  {({ getRootProps }) => {
                    return (
                      <div {...getRootProps()}>
                        {/* 
                        <p className="timeline-left-header font-itc">
                          Henkilöstö
                        </p> */}

                        {!btnAllOpen ? (
                          <UpOutlined
                            className="timeline-left-arrow"
                            onClick={() => {
                              handleBtnOpenAll();
                            }}
                          />
                        ) : (
                          <DownOutlined
                            className="timeline-left-arrow"
                            onClick={() => {
                              handleBtnOpenAll();
                            }}
                          />
                        )}

                        {/*                {item.open ? (
                        <UpOutlined className="arrow" />
                      ) : (
                        <DownOutlined className="arrow" />
                      )} */}
                      </div>
                    );
                  }}
                </SidebarHeader>
                <DateHeader unit="week" />
                <DateHeader />
              </TimelineHeaders>
            </Timeline>
          </div>
        ) : (
          <Loading />
        )}
      </div>
      ,
    </>
  );
};
