import { CloseOutlined } from "@ant-design/icons";
import { useEffect, useState } from "react";

import { Input, Modal, Button } from "antd";
import dayjs from "dayjs";
import { useTranslate } from "../../../components/Translate";


export const EditAddressModal = ({ isOpen, closeModal, data, saveFunc }) => {
  //console.log("EditAddressModal data", data);
  const [address1, setAddress1] = useState(null);
  const [address2, setAddress2] = useState(null);

  const { locale } = useTranslate();
  useEffect(() => {
    if (isOpen) {
      setAddress1(data.address1)
      setAddress2(data.address2)
    } else {
      setAddress1(null)
      setAddress2(null)
    }

  }, [isOpen]);

  return (
    <>
      <Modal
        open={isOpen}
        onCancel={() => closeModal()}
        title={`Muokkaa toimitusosoitetta`}
        centered={true}
        footer={[
          <Button key="submit" type="primary" color="red" onClick={() => {
            closeModal()
            saveFunc(address1, address2)
          }}
          >
            {locale.save}
          </Button>,
        ]}
      >
        <div>
          <p className="project-modal-label font-dm-medium">{"Osoite"}</p>
          <Input
            value={address1}
            onChange={(e) => {
              setAddress1(e.target.value)
              console.log("onChange", e.target.value)
            }}
          />

          <p className="project-modal-label font-dm-medium">{"Postiosoite"}</p>
          <Input
            value={address2}
            onChange={(e) => {
              setAddress2(e.target.value)
              console.log("onChange", e.target.value)
            }}
          />
        </div>
      </Modal>
    </>
  );
};
