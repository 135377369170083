import { CloseOutlined } from "@ant-design/icons";
import { useEffect, useState } from "react";

import { Button, DatePicker, Input, Modal } from "antd";
import dayjs from "dayjs";
import { useTranslate } from "../../../components/Translate";
import { getEnvSettings } from "../../../config/config";
import { apiSecure } from "../../../components/api";

const { RangePicker } = DatePicker;


export const EditStageData = ({ isOpen, closeModal, projectId, data, saveFunc, getProject}) => {
  console.log("EditStageData data", data);
  const envConfig = getEnvSettings();

  const [name, setName] = useState(null);
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const { locale } = useTranslate();

  useEffect(() => {

    if (isOpen) {

      let newName = data.name
      let startdate = data.start && dayjs(data.start)
      let enddate = data.end && dayjs(data.end)

      console.log("Name", newName)
      console.log('startDate', startdate)
      console.log('enddate', enddate)

      if (newName) setName(newName)
      if (startdate) setStartDate(startdate)
      if (enddate) setEndDate(enddate)

    } else {
      setName(null)
      setStartDate(null)
      setEndDate(null)
    }

  }, [isOpen]);





  const canDeleteStage = () => {
    console.log('canDeleteStage');
    return new Promise((resolve, reject) => {
      Modal.confirm({
        title: 'Haluatko varmasti poistaa etapin',
        content: <div>
          <p className="font-dm-regular" style={{ margin: 0 }}>Huom! Tätä ei voi perua.</p>

          {data.parent &&
            <p className="font-dm-regular" style={{ margin: 0 }}>Tämä poistaa myös alaetapit!</p>}
        </div>,
        okText: 'Kyllä',
        cancelText: 'Ei',
        centered: true,
        onOk: () => {
          resolve(true);  // Kutsutaan resolve, kun "yes" valitaan
        },
        onCancel: () => {
          resolve(false);  // Ratkaistaan lupaus arvoon false, kun "no" valitaan
        }
      });
    });
  };
  const deleteStage = async () => {
    console.log('deleteStage', data)

    if (data.id) {

      let canDelete = await canDeleteStage()
      if (!canDelete) return

      let req_config = {
        method: "POST",
        url: `${envConfig.apiUrlPrefix}deleteStage`,
        data: {
          projectId: projectId,
          stage: data.id,
        },
      };

      apiSecure(req_config)
        .then((response) => {
          closeModal();
          getProject();
        })
        .catch((error) => {
          console.log("Error:", error);
        });

    };

  }



  return (
    <>
      <Modal
        open={isOpen}
        onCancel={() => closeModal()}
        title={locale.etapedit}
        centered={true}
        footer={[
          <Button key="remove" type="primary" danger
            style={{ marginRight: '1em' }}
            onClick={() => {
              deleteStage()
            }}
          >
            Poista
          </Button>,
          <Button key="back" onClick={closeModal}>
            Peruuta
          </Button>,
          <Button key="submit" type="primary" color="red" onClick={() => {
            closeModal()
            saveFunc(data.id, name, startDate ? dayjs(startDate).format("YYYY-MM-DD") : null, endDate ? dayjs(endDate).format("YYYY-MM-DD") : null)
          }}>
            Tallenna
          </Button>,
        ]}
      >
        <div>
          <p className="project-modal-label font-dm-medium">{locale.etapeditname}</p>
          <Input
            value={name}
            onChange={(e) => {
              setName(e.target.value)
              console.log("onChange", e.target.value)
            }}
          />

          <p className="project-modal-label font-dm-medium">{locale.etapeditdate}</p>
          <RangePicker
            className="rangepicker"
            size="small"
            showWeek={true}
            suffixIcon={null}
            value={[startDate, endDate]}
            format={'DD.MM.YYYY'}
            onChange={(value) => {
              setStartDate(value[0]);
              setEndDate(value[1]);
            }}
            onOk={() => {
              console.log("onOk");
            }}
          />
        </div>
      </Modal>
    </>
  );
};
