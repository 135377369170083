import { CloseOutlined } from "@ant-design/icons";
import { useEffect, useState } from "react";

import { Input, Modal, Button } from "antd";
import dayjs from "dayjs";
import { useTranslate } from "../../../components/Translate";
import { apiSecure } from "../../../components/api";
import { getEnvSettings } from "../../../config/config";
import { LockOutlined, UnlockOutlined } from '@ant-design/icons';

const { TextArea } = Input;



const ModalTextItem = ({ label, value, setValue, disabled = false, canLock = false, locked, setLocked }) => {
  const { locale } = useTranslate();

  return (<div>
    <p className="project-modal-label font-dm-medium">{label}</p>
    <Input
      value={value}
      disabled={locked || disabled}
      onChange={(e) => {
        setValue(e.target.value)
        console.log("onChange", e.target.value)
      }}
      addonAfter={canLock ? <>{locked ? <LockOutlined onClick={() => { setLocked(false) }} /> : <UnlockOutlined onClick={() => { setLocked(true) }} />}</> : null}
    />
  </div>
  );
};

const ModalTextAreaItem = ({ label, value, setValue, disabled = false }) => {
  const { locale } = useTranslate();

  return (<div>
    <p className="project-modal-label font-dm-medium">{label}</p>
    <TextArea rows={4} value={value}
      disabled={disabled}
      onChange={(e) => {
        setValue(e.target.value)
        console.log("onChange", e.target.value)
      }} />
  </div>
  );
};

const formatNull = (data) => {
  return data ? data : null
};

export const EditContactModal = ({ isOpen, closeModal, projectId, data, refreshData }) => {
  //console.log("EditContactModal data", data);

  const [isLoading, setIsLoading] = useState(false);

  const [role, setRole] = useState(null);
  const [name, setName] = useState(null);
  const [company, setCompany] = useState(null);
  const [phone1, setPhone1] = useState(null);
  const [phone2, setPhone2] = useState(null);
  const [mail, setMail] = useState(null);
  const [address1, setAddress1] = useState(null);
  const [address2, setAddress2] = useState(null);
  const [otherInfo, setOtherInfo] = useState(null);

  const [nameLocked, setNameLocked] = useState(true);
  const [companyLocked, setCompanyLocked] = useState(true);

  const { locale } = useTranslate();
  const envConfig = getEnvSettings();



  useEffect(() => {

    if (isOpen) {
      setRole(data?.OnkoTilaaja ? "Tilaaja" : data.Toimenkuva)
      setName(data.Henkilo)
      setCompany(data.Yritys)
      setPhone1(data.GSM)
      setPhone2(data.Puhelin)
      setMail(data.Sahkoposti)
      setAddress1(data.Lahiosoite)
      setAddress2(data.Postiosoite)
      setOtherInfo(data.Muu_info)
    } else {
      setRole(null)
      setName(null)
      setCompany(null)
      setPhone1(null)
      setPhone2(null)
      setMail(null)
      setAddress1(null)
      setAddress2(null)
      setOtherInfo(null)
      setNameLocked(true)
      setCompanyLocked(true)
    }
  }, [isOpen]);



  const saveData = () => {
    setIsLoading(true)

    let req_config = {
      method: "POST",
      url: `${envConfig.apiUrlPrefix}updateContact`,
      data: {
        projectId: projectId,
        contactId: data.YhteysNRO,
        data: {
          isOrderer: data?.OnkoTilaaja,
          role: data?.OnkoTilaaja ? null : role,
          name: formatNull(name),
          company: formatNull(company),
          phone1: formatNull(phone1),
          phone2: formatNull(phone2),
          mail: formatNull(mail),
          address1: formatNull(address1),
          address2: formatNull(address2),
          otherInfo: formatNull(otherInfo),
        },
      },
    };
    console.log("updateContact", req_config);

    apiSecure(req_config)
      .then((response) => {
        console.log("RESPONSE", response.data);
        closeModal()
        refreshData()
        setIsLoading(false)
      })
      .catch((error) => {
        console.log("Error:", error);
      });

  }



  const canDeleteProjectContact = () => {
    console.log('canDeleteProjectContact');

    return new Promise((resolve, reject) => {
      Modal.confirm({
        title: `Haluatko varmasti poistaa (${data.Henkilo}) projektin yhteystiedoista`,
        okText: 'Kyllä',
        cancelText: 'Ei',
        centered: true,
        onOk: () => {
          resolve(true);  // Kutsutaan resolve, kun "yes" valitaan
        },
        onCancel: () => {
          resolve(false);  // Ratkaistaan lupaus arvoon false, kun "no" valitaan
        }
      });
    });
  };

  const deleteProjectContact = async () => {
    console.log('deleteProjectContact')
    let canDelete = await canDeleteProjectContact()
    if (!canDelete) return
    
    setIsLoading(true)
    
    let req_config = {
      method: "POST",
      url: `${envConfig.apiUrlPrefix}deleteProjectContact`,
      data: {
        projectId: projectId,
        contactId: data.YhteysNRO,
      }
    };

    apiSecure(req_config)
      .then((response) => {
        const { status } = response?.data
        closeModal();
        refreshData()
        setIsLoading(false)
      })
      .catch((error) => {
        console.log("Error:", error);
      });

  }

  return (
    <>
      <Modal
        open={isOpen}
        onCancel={() => closeModal()}
        title={`Muokkaa yhteystietoa`}
        centered={true}
        footer={[

          !data?.OnkoTilaaja && projectId && <Button key="remove" type="primary" danger
            style={{ marginRight: '1em' }}
            onClick={() => {
              deleteProjectContact()
            }}
            loading={isLoading}
          >
            Poista yhteystieto projektilta
          </Button>,

          <Button key="submit" type="primary" color="red" onClick={() => {
            saveData()
          }}
            loading={isLoading}>
            Tallenna
          </Button>,
        ]}
      >
        <div>
          {projectId ?
            <ModalTextItem
              value={role}
              setValue={setRole}
              label={"Rooli"}
              disabled={data?.OnkoTilaaja ? true : false}
            />
            : <></>}

          <ModalTextItem
            value={name}
            setValue={setName}
            label={"Nimi"}
            canLock={true}
            locked={nameLocked}
            setLocked={setNameLocked}
          />

          <ModalTextItem
            value={company}
            setValue={setCompany}
            label={"Yritys"}
            canLock={true}
            locked={companyLocked}
            setLocked={setCompanyLocked}
          />

          <ModalTextItem
            value={phone1}
            setValue={setPhone1}
            label={"Puhelin"}
          />
          <ModalTextItem
            value={phone2}
            setValue={setPhone2}
            label={""}
          />

          <ModalTextItem
            value={mail}
            setValue={setMail}
            label={"Sähköposti"}
          />

          <ModalTextItem
            value={address1}
            setValue={setAddress1}
            label={"Osoite"}
          />

          <ModalTextItem
            value={address2}
            setValue={setAddress2}
            label={""}
          />

          <ModalTextAreaItem
            value={otherInfo}
            setValue={setOtherInfo}
            label={"Huomautuksia"}
          />
        </div>
      </Modal>
    </>
  );
};
