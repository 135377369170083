import { forwardRef, useEffect, useImperativeHandle, useRef, useState } from "react";
import { apiSecure } from "../../../components/api";
import { Loading, LoadingItem } from "../../../components/Loading/Loading";

import {
  MergeCellsOutlined,
  PlusOutlined,
  EditOutlined,
} from "@ant-design/icons";


import { DatePicker } from "antd";
import { getParsedDate, getParsedTime } from "../../../components/globalFunctions";

import { useTranslate } from "../../../components/Translate";

import { Draggable, Droppable } from "react-beautiful-dnd";

import moment, { isMoment } from "moment";


import { copy, move, reorder } from "../ProjectDraggable";
import dayjs from "dayjs";
import { getEnvSettings } from "../../../config/config";
import { EditDeliveryData } from "./EditDeliveryData";
import { useSelector } from "react-redux";


/* 
 

*/

export const ProjectDroppableDeliverys = forwardRef(({ id, projectData, setItemsInDroppable, enableEditing, setEnableEditing, getProject }, ref) => {
  const { locale } = useTranslate();
  const envConfig = getEnvSettings();

  const { tehtaat, selectedFactory } = useSelector((state) => state.currentUser.user);

  //const [enableEditing, setEnableEditing] = useState(false);
  const [copiedList, setCopiedList] = useState({});
  const [droppableLists, setDroppableLists] = useState({});
  const [droppableCalculations, setDroppableCalculations] = useState({});

  const [deliveryDataOpen, setDeliveryDataOpen] = useState(false);
  const [deliveryData, setDeliveryData] = useState({});

  useEffect(() => {
    console.log('Updated droppableLists 2:', droppableLists);
    let nData = {}

    Object.keys(droppableLists).forEach((list) => {
      const listdata = droppableLists[list];

      let otherWeight = listdata?.deliveryData?.MuutTarvikkeetPaino ? listdata?.deliveryData?.MuutTarvikkeetPaino : 0;
      let elemWeights = 0;
      let totalWidth = 0;

      listdata?.items.forEach((item) => {
        let itemId = parseInt(item.id.replace("_dropped", "")); // Hae itemId poistamalla "_dropped" ja muuntamalla kokonaisluvuksi
        let rowdata = getRowData(itemId); // Hae rowdata itemId:n avulla
        elemWeights += (rowdata.Paino || 0); // Varmista, että käytetään 0, jos weight ei ole määritelty
        totalWidth += (rowdata.Paksuus || 0); // Varmista, että käytetään 0, jos weight ei ole määritelty
      }, 0);

      nData[list] = {
        otherWeight: otherWeight,
        elemWeights: elemWeights,
        totalWeight: elemWeights + otherWeight,
        totalWidth: totalWidth
      }
    });
    console.log('setDroppableCalculations', nData)
    setDroppableCalculations(nData)
  }, [droppableLists]); // Tämä varmistaa, että muutokset näkyvät


  useEffect(() => {
    console.log('Updated droppableCalculations 2:', droppableCalculations);

  }, [droppableCalculations]); // Tämä varmistaa, että muutokset näkyvät


  //Initial etappien luonti
  useEffect(() => {
    if (projectData?.kuormakirja?.length > 0) {
      let newData = {};

      console.log('kuormakirja22', projectData?.kuormakirja)

      // Käy läpi kuormakirjat ja luo alkuperäinen data
      projectData.kuormakirja.forEach((item) => {

        //Toimitettuja kuormakirjoja ei kosketa ollenkaan
        if (item.OnkoToimitettu) return

        newData[`LIST_${item.KKNro}`] = {
          title: `Kuorma ${item?.KuormakirjaNumero}`,
          items: [],
          deliveryData: item,
        };
      });


      // Käy läpi elementtiryhmät ja lisää ne oikeisiin listoihin
      projectData.elementtiryhmat.forEach((group) => {
        group.data?.forEach((item) => {

          console.log('item22', item)

          if (item.KKNro && newData[`LIST_${item.KKNro}`]) {
            newData[`LIST_${item.KKNro}`].items.push({
              id: `${item.ValmistusNro}_dropped`,
              order: item.KuormanAsennusJarjestys,
              /*  weight: item.Paino, */
            });
          }
        });
      });

      // Laitetaan etapit järjestykseen
      Object.values(newData).forEach((list) => {
        list.items.sort((a, b) => a.order - b.order);
      });

      console.log('setDroppableLists(newData);', newData)
      setDroppableLists(newData);
    }
  }, [projectData]);




  // Päivitetään tieto elementeistä, jotka ovat etapeissa
  useEffect(() => {
    if (Object.keys(droppableLists).length === 0) return;

    const updatedItemsInDroppable = Object.keys(droppableLists).reduce((acc, listKey) => {
      droppableLists[listKey].items.forEach((item) => {
        const itemId = parseInt(item.id.replace("_dropped", ""), 10);
        acc[itemId] = true;
      });
      return acc;
    }, {});

    console.log("itemsInDroppable", updatedItemsInDroppable);
    setItemsInDroppable(updatedItemsInDroppable); // Päivitetään state
  }, [droppableLists]);


  const handleDragEnd = (result) => {
    const { source, destination, draggableId } = result;
    console.log("handleDragEnd ==> result", result);

    // dropped outside the list
    if (!destination) return;


    //Mikäli kuorman tehdas on eri kuin siirrettävän elementin elementtiryhmän tehdas niin ei siirretä
    if (destination.droppableId.includes("LIST")) {
      let targetList = droppableLists[destination.droppableId]
      let targetFactory = targetList?.deliveryData?.Tehdas

      let itemId = parseInt(draggableId.replace("_dropped", ""));
      let itemData = getRowData(itemId); // Hae rowdata itemId:n avulla

      if (itemData.ElementtiRyhmaTehdas !== targetFactory) {
        return
      }
    }


    switch (true) {
      case source.droppableId === destination.droppableId: //Order vaihto listassa
        setDroppableLists({
          ...droppableLists,
          [destination.droppableId]: {
            ...droppableLists[destination.droppableId],
            items: reorder(
              droppableLists[source.droppableId].items,
              source.index,
              destination.index
            ),
          },
        });
        break;

      case source.droppableId.includes("GROUP"): //elementtilistasta tauluun siirto
        let isInArray = isDraggableInAnyList(result.draggableId);
        if (isInArray) break;

        setDroppableLists({
          ...droppableLists,
          [destination.droppableId]: {
            ...droppableLists[destination.droppableId],
            items: copy(
              null,
              draggableId + "_dropped",
              droppableLists[destination.droppableId].items,
              destination
            ),
          },
        });
        break;

      case source.droppableId.includes("LIST"): //Taulusta toiseen siirto
        setDroppableLists({
          ...returnRemoveDraggableArray(source.droppableId, draggableId),
          [destination.droppableId]: {
            ...droppableLists[destination.droppableId],
            items: copy(
              source.droppableId,
              draggableId,
              droppableLists[destination.droppableId].items,
              destination
            ),
          },
        });
        break;

      default:
        break;
    }
  };

  // expose the functions to parent component via ref
  useImperativeHandle(ref, () => ({
    handleDragEnd
  }));

  const getRowData = (id) => {
    if (!projectData.elementtiryhmat) return;

    for (const group of projectData.elementtiryhmat) {
      if (!group.data) continue;

      const foundItem = group.data.find((element) => element.ValmistusNro === id);

      if (foundItem) {
        return foundItem;
      }
    }

    return {}; // Palauttaa tyhjän objektin, jos ei löydy mitään
  };

  const handleOpenDeliveryData = (deliveryId) => {
    if (enableEditing) return;
    const deliveryData = projectData.kuormakirja.find(itm => itm.KKNro === deliveryId)
    console.log("handleOpenDeliveryData", deliveryId, deliveryData);
    if (!deliveryData?.KKNro) return //Kuljetusta ei jostain syystö löydy
    setDeliveryData(deliveryData);
    setDeliveryDataOpen(true);
  };

  const returnRemoveDraggableArray = (list_id, draggable_id) => {
    return {
      ...droppableLists,
      [list_id]: {
        ...droppableLists[list_id],
        items: droppableLists[list_id].items.filter(
          (item) => item.id !== draggable_id
        ),
      },
    };
  };

  const removeDraggable = (list_id, draggable_id) => {
    setDroppableLists(returnRemoveDraggableArray(list_id, draggable_id));
  };

  const isDraggableInAnyList = (draggable_id) => {
    return Object.keys(droppableLists).some((list) =>
      droppableLists[list].items.some((x) => x.id === draggable_id + "_dropped")
    );
  };

  //////
  //////  DRAGGING END
  //////



  //Päiivtetään kaikkien kuormien sisältö *VAATIVA JA TÄRKEÄ* jos et tiedä mitä teet, kysy.
  const updateDeliverys = () => {
    console.log("updateDeliverys start", droppableLists);

    setCopiedList({});
    setEnableEditing(!enableEditing);

    let newList = [];
    let newElements = [];

    Object.keys(droppableLists).map((list, index) => {
      let listId = parseInt(list.replace("LIST_", ""));

      //Listat ja niiden painot
      newList.push({
        deliveryid: listId,
        elemWeights: droppableCalculations[list].elemWeights
      })

      //Elementtitiedot
      droppableLists[list].items.length >= 1 &&
        droppableLists[list].items.map((item, index2) => {
          let itemId = parseInt(item.id.replace("_dropped", ""));
          newElements.push({
            elementid: itemId,
            deliveryid: listId,
            order: index2 + 1,
          });
        });
    });

    let req_config = {
      method: "POST",
      url: `${envConfig.apiUrlPrefix}updateDeliverys`,
      data: {
        projectId: id,
        lists: newList,
        elements: newElements,
      },
    };
    console.log('req_config', req_config)
    apiSecure(req_config)
      .then((response) => {
        getProject();
        // console.log("RESPONSE", response.data);
      })
      .catch((error) => {
        console.log("Error:", error);
      });
  };

  const addDelivery = (stage, name, start, end) => {
    let req_config = {
      method: "POST",
      url: `${envConfig.apiUrlPrefix}newDelivery`,
      data: {
        projectId: id,
        selectedFactory: selectedFactory
      },
    };

    apiSecure(req_config)
      .then((response) => {
        //   console.log("RESPONSE", response.data);
        getProject();
      })
      .catch((error) => {
        console.log("Error:", error);
      });
  };





  return (<>

    <EditDeliveryData
      isOpen={deliveryDataOpen}
      closeModal={() => {
        setDeliveryDataOpen(false);
      }}
      projectId={id}
      deliveryData={deliveryData}
      getProject={getProject}
    />


    {/* Jos yhtään etappia ei ole, näytetään placeholder josta voi lisätä etapin */}
    {projectData.kuormakirja.length <= 0 ?
      <>
        <div className={`droppablecontainer-placeholder`}>
          <div className={`droppablegroup-placeholder`}>
            <div className={`droppablebox parent`}  >
              <div className={`droppablebox-header`}>
                <div>
                  <p className={`droppablebox-placeholder-header-title font-dm-bold`}>
                    {locale.deliverys}
                  </p>

                  <p className={`droppablebox-placeholder-text font-dm-regular`}>
                    {locale.deliverys_introduction}
                  </p>


                  <button
                    className="buttonstyle font-dm-regular"
                    style={{ marginLeft: 0, marginTop: "1.5em" }}
                    onClick={() => {
                      addDelivery()
                    }}
                  >
                    <PlusOutlined style={{ fontSize: 15, marginRight: -5 }} />
                    {locale.deliverys_add}
                  </button>

                </div>
              </div>
            </div>
          </div>
        </div>
      </>
      : <>

        <div className={`droppablecontainerFixed`}>
          <div className={`droppablecontainerInner`}>
            {Object.keys(droppableLists).map((list, i) => {
              const listdata = droppableLists[list];
              console.log('LIST:', list, listdata)

              const isOverweight = listdata?.deliveryData?.AutonKantavuus > 0 ? (droppableCalculations[list]?.totalWeight > listdata?.deliveryData?.AutonKantavuus) : false

              return (
                <>
                  <div className={`droppablegroup ${enableEditing}`}>
                    <Droppable
                      key={list}
                      droppableId={list}
                      isDropDisabled={!enableEditing}
                    >
                      {(provided, snapshot) => {
                        return (
                          <div
                            className={`droppablebox parent`}
                          >
                            <div className={`droppablebox-header-delivery`}>
                              <div>
                                <p className={`droppablebox-header-title font-dm-bold`}>
                                  {listdata.title}

                                  {!enableEditing && (
                                    <EditOutlined
                                      key={list}
                                      id={list}
                                      style={{ marginLeft: '5px', fontSize:'15px' }}
                                      className="droppablegroup-icon"
                                      onClick={() => {
                                        let itemId = parseInt(
                                          list.replace("LIST_", "")
                                        );
                                        handleOpenDeliveryData(itemId);
                                      }}
                                    />
                                  )}
                                  {/* Auton tiedot */}
                                  {listdata?.deliveryData?.AutonNimi ?
                                    <span className={`droppablebox-header-delivery-vehicle font-dm-regular ${isOverweight ? 'overWeight' : ''}`}>
                                      {listdata?.deliveryData?.AutonNimi} {`${listdata?.deliveryData?.AutonKantavuus > 0 ? `(${listdata?.deliveryData?.AutonKantavuus} t)` : ""}`}
                                    </span>
                                    : <></>}
                                </p>

                                <div className="droppablebox-header-delivery-2row">
                                  <span className={`droppablebox-header-delivery-factory font-dm-bold`}>
                                    <span>
                                      {`${tehtaat[listdata?.deliveryData?.Tehdas] ? tehtaat[listdata?.deliveryData?.Tehdas].name : 'TehdasNull'} `}
                                    </span>

                                    <span style={{marginLeft: '3px'}}>
                                      {` ${listdata?.deliveryData?.ToimitusPVM ? getParsedDate(listdata?.deliveryData?.ToimitusPVM) : ""} `}
                                    </span>

                                    <span style={{marginLeft: '3px'}}>
                                      {` ${listdata?.deliveryData?.PurkuKlo ? getParsedTime(listdata?.deliveryData?.PurkuKlo) : ""}`}
                                    </span>
                                  </span>

                                  <span className={`droppablebox-header-delivery-2row-right font-dm-bold`}>
                                    <span className={`droppablebox-header-delivery-width`}>
                                      {`∑ d=${droppableCalculations[list]?.totalWidth?.toFixed(0)} mm`}
                                    </span>

                                    <span className={`droppablebox-header-delivery-kpl`}>
                                      {`${listdata?.items?.length} kpl`}
                                    </span>

                                    <span className={`droppablebox-header-delivery-weight ${isOverweight ? 'overWeight' : ''}`}>
                                      {`${droppableCalculations[list]?.totalWeight?.toFixed(1)} t`}
                                    </span>
                                  </span>
                                </div>
                              </div>
                            </div>

                            <div
                              ref={provided.innerRef}
                              className="droppableBack"
                              style={{
                                backgroundColor:
                                  snapshot.isDraggingOver
                                    ? "#244cdb3c"
                                    : "white",
                              }}
                            >
                              <div className="droppableInside">
                                {droppableLists[list].items.length >=
                                  1
                                  ? droppableLists[list].items.map(
                                    (item, index) => {
                                      let itemId = parseInt(item.id.replace("_dropped", ""));
                                      let rowdata = getRowData(itemId);

                                      return (
                                        <Draggable
                                          key={item.id}
                                          draggableId={item.id}
                                          index={index}
                                          isDragDisabled={
                                            !enableEditing
                                          }
                                        >
                                          {(provided, snapshot) => (
                                            <div
                                              className={`draggableItemDelivery ${rowdata.ValmistusPVM ? '' : 'notConstruced'}`}
                                              ref={
                                                provided.innerRef
                                              }
                                              {...provided.draggableProps}
                                              {...provided.dragHandleProps}
                                              style={
                                                provided
                                                  .draggableProps
                                                  .style
                                              }
                                            >


                                              <div lassName="draggableItemDeliveryOrder">
                                                <span>{index + 1 + '.'}</span>
                                              </div>

                                              <div style={{ flex: 1, marginLeft: '0.8em' }}>
                                                {/* Eka rivi */}
                                                <div style={{
                                                  display: 'flex',
                                                  flexDirection: 'row'
                                                }}>
                                                  <p
                                                    ro
                                                    className="font-dm-regular draggableItemDeliveryText">
                                                    {rowdata.Tunnus}

                                                  </p>

                                                  <p className="font-dm-regular draggableItemDeliveryText2">   {
                                                    rowdata.ValmistusNro
                                                  }</p>
                                                </div>

                                                {/* Toka rivi */}
                                                <p className="font-dm-regular draggableItemDeliveryTextWeight">
                                                  {`${rowdata?.Paino?.toFixed(1) || 0} t `}

                                                  {` ${rowdata?.Pituus || 0} x ${rowdata?.Korkeus || 0} ${rowdata?.Korkeus !== rowdata?.KorkeusKuljetus ? `(${rowdata?.KorkeusKuljetus || 0})` : ''} x ${rowdata?.Paksuus || 0}`}
                                                </p>
                                              </div>


                                              {/* Tpoisto */}
                                              {enableEditing && (
                                                <PlusOutlined
                                                  style={{
                                                    fontSize: 20,
                                                  }}
                                                  className="draggableItemClose"
                                                  onClick={() => {
                                                    removeDraggable(
                                                      list,
                                                      item.id
                                                    );
                                                  }}
                                                />
                                              )}
                                            </div>
                                          )}
                                        </Draggable>
                                      );
                                    }
                                  )
                                  : null}
                                {provided.placeholder}
                              </div>



                              {/* Muut tarvikkeet paino */}
                              {droppableCalculations[list]?.otherWeight ?
                                <div className="draggableItemDelivery" style={{ backgroundColor: '#CDCDCD' }} >
                                  <div style={{ flex: 1, marginLeft: '0.8em' }}>

                                    <div style={{
                                      display: 'flex',
                                      flexDirection: 'row'
                                    }}>
                                      <p
                                        className="font-dm-regular draggableItemDeliveryText">
                                        {"Muut tarvikkeet"}
                                      </p>
                                    </div>


                                    {/* Toka rivi */}
                                    <p className="font-dm-regular draggableItemDeliveryTextWeight" >
                                      {`${droppableCalculations[list]?.otherWeight?.toFixed(1) || 0} t `}
                                    </p>
                                  </div>
                                </div>
                                : <></>}



                            </div>
                          </div>
                        );
                      }}
                    </Droppable>


                  </div>
                </>
              );
            })}
            <></>
          </div>

          {!enableEditing && (
            <div className="etappiBtns">
              <button
                className="buttonstyle font-dm-regular"
                onClick={() => {
                  addDelivery()
                }}
              >
                <PlusOutlined style={{ fontSize: 15, marginRight: -5 }} />
                {locale.deliverys_add}
              </button>

              <button
                className="buttonstyle font-dm-regular"
                onClick={() => {
                  setCopiedList(
                    JSON.parse(JSON.stringify(droppableLists))
                  );
                  setEnableEditing(!enableEditing);
                }}
              >
                <MergeCellsOutlined
                  style={{ fontSize: 15, marginRight: -5 }}
                />
                {locale.deliverys_control2}
              </button>
            </div>
          )}

          {enableEditing && (
            <div className="etappiBtns">
              <button
                className="buttonstyle font-dm-regular"
                style={{
                  backgroundColor: "#25D155",
                  width: "150px",
                }}
                onClick={() => {
                  updateDeliverys();
                }}
              >
                {locale.save}
              </button>

              <button
                className="buttonstyle font-dm-regular"
                style={{
                  backgroundColor: "#fff",
                  border: "1px solid grey",
                  color: "#575757",
                  width: "150px",
                }}
                onClick={() => {
                  setDroppableLists(copiedList);
                  setEnableEditing(!enableEditing);
                  getProject();
                }}
              >
                {locale.cancel}
              </button>
            </div>
          )}
        </div>
      </>}


  </>);
});
