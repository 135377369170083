import { useCallback, useEffect, useMemo, useState } from "react";
import { apiSecure } from "../../components/api";
import { Loading } from "../../components/Loading/Loading";
import "./MapPage.css";

import { MenuFoldOutlined, MenuOutlined } from "@ant-design/icons";
import { useNavigate } from "react-router-dom";
import { useTranslate } from "../../components/Translate";
import { useDispatch, useSelector } from "react-redux";
import config, { getEnvSettings } from "../../config/config";


import { AdvancedMarker, APIProvider, ControlPosition, Map, MapControl, Marker, Pin, useMap } from '@vis.gl/react-google-maps';
import { Dropdown } from "antd";
import { useMediaQuery } from "react-responsive";
import { MapCustomBtns, RenderMapInfo } from "../../components/mapComponents";
import { MarkerClusterer } from "@googlemaps/markerclusterer";



const RenderFilters = ({ toggleFilter, filterActive, archiveYearsMenu, archiveFilter }) => {
  const [menuVisible, setMenuVisible] = useState(false);
  const isTabletOrMobile = useMediaQuery({ maxWidth: 1000 });


  const mobileMenu = [
    {
      label: (
        <p className="map-mobile-selector-item font-dm-medium">Tuotanto</p>
      ),
      key: "3",
      onClick: () => toggleFilter("3"),
    },
    {
      label: (
        <p className="map-mobile-selector-item font-dm-medium">Tarjouspyyntö</p>
      ),
      key: "1",
      onClick: () => toggleFilter("1"),
    },
    {
      label: (
        <p className="map-mobile-selector-item font-dm-medium">Tarjoukset</p>
      ),
      key: "2",
      onClick: () => toggleFilter("2"),
    },

    {
      label: (
        <span className="map-mobile-selector-item font-dm-medium">Arkisto</span>
      ),
      key: "5",
      children: archiveYearsMenu
    }
  ]

  return (<MapControl
    position={ControlPosition.BLOCK_START_INLINE_CENTER}
  >
    <div className="map-selector">

      {!isTabletOrMobile ? (
        <div className="map-selector-group">
          <div
            className={`map-selector-item left font-dm-medium ${filterActive.includes("3") && "active"}`}
            onClick={() => toggleFilter("3")}
          >
            <p className="map-selector-label">Tuotanto</p>
          </div>
          <div
            className={`map-selector-item font-dm-medium ${filterActive.includes("1") && "active"}`}
            onClick={() => toggleFilter("1")}
          >
            <p className="map-selector-label">Tarjouspyyntö</p>
          </div>
          <div
            className={`map-selector-item font-dm-medium ${filterActive.includes("2") && "active"}`}
            onClick={() => toggleFilter("2")}
          >
            <p className="map-selector-label">Tarjoukset</p>
          </div>

          <Dropdown menu={{
            items: archiveYearsMenu,
            selectable: true,
            multiple: true,
          }}
            trigger={['hover']}
            getPopupContainer={() => document.querySelector('.gm-style')} // Määritä kartan sisäinen elementti
          >
            <div
              className={`map-selector-item right font-dm-medium ${archiveFilter.length > 0 && "active"}`}
            >
              <p className="map-selector-label">Arkisto</p>
{/*               <p className="map-selector-label-2">{archiveFilter.map((itm)=><span className="font-dm-medium map-selector-archive-year">{itm}</span>)}</p>
 */}            </div>
          </Dropdown>
        </div>)
        : (<Dropdown
          menu={{
            items: mobileMenu,
            selectable: true,
            multiple: true,
            defaultSelectedKeys: ['3'],
          }}
          trigger={["click"]}
          open={menuVisible}
          onOpenChange={(visible) => {
            setMenuVisible(visible);
          }}
          placement="bottomRight"
          className="map-mobile-selector"
          getPopupContainer={() => document.querySelector('.gm-style')} // Määritä kartan sisäinen elementti
        >
          {menuVisible ? (
            <MenuFoldOutlined className="header-dropdown" style={{ color: '#333' }} />
          ) : (
            <MenuOutlined className="header-dropdown" style={{ color: '#333' }} />
          )}
        </Dropdown>)}
    </div>
  </MapControl>)

}


const ProjectMarker = ({ item, itemIndex, handleMarkerClick, setMarkerRef }) => {

  let color = item?.Varikoodi || '#333';
  let projId = 'proj_' + item.Tyokohdenro;

  var newlat = parseFloat(item.lat.replace(/,/g, "."));
  var newlng = parseFloat(item.lng.replace(/,/g, "."));

  const ref = useCallback(
    (marker) =>
      setMarkerRef(marker, projId),
    [setMarkerRef, projId]
  );

  if (!item.lat || !item.lng) return null;

  return (
    <AdvancedMarker
      ref={ref}
      key={projId}
      position={{ lat: newlat, lng: newlng }}
      onClick={() => handleMarkerClick({
        isProject: true,
        name: item.Nimi,
        id: item.Tyokohdenro,
        status: item?.TilaNimi,
        lat: newlat,
        lng: newlng,
      })}
      zIndex={itemIndex}
    >
      <Pin
        background={color}
        borderColor="#333"
        glyphColor={color}
        scale={0.8}
      />
    </AdvancedMarker>
  );
}

const createCustomRenderer = (color) => ({
  render: ({ count, position }) => {
    // Käytä customValue arvoa klusterin ulkoasussa
    /*     const pinElement = new google.maps.marker.PinElement({
          glyph: `${count}`, // Esimerkki, yhdistää customValue ja count
          glyphColor: "white",
          background: color,
          borderColor: "#333",
        });
    
     */
    // Luodaan mukautettu pyöreä content-elementti
    const content = document.createElement("div");
    content.style.width = "30px";
    content.style.height = "30px";
    content.style.borderRadius = "50%";
    content.style.backgroundColor = "gray"; // Taustaväri
    content.style.color = "white";
    content.style.display = "flex";
    content.style.justifyContent = "center";
    content.style.alignItems = "center";
    content.style.fontSize = "14px";
    content.style.fontWeight = "bold";
    content.textContent = count; // Näytetään klusterin määrä

    return new window.google.maps.marker.AdvancedMarkerElement({
      position: position,
      content: content,//pinElement.element,
      zIndex: 2500
    });
  },
});

const RenderProjectMarkers = ({ id, items, handleMarkerClick }) => {
  const [markers, setMarkers] = useState({});

  console.log('markers', markers)

  const markerColors = items[0]?.Varikoodi || '#333'

  const map = useMap(id);

  const clusterer = useMemo(() => {
    if (!map) return null;

    return new MarkerClusterer({
      map,
      renderer: createCustomRenderer('gray')
    });
  }, [map]);


  useEffect(() => {
    if (!clusterer) return;
    clusterer.clearMarkers();
    clusterer.addMarkers(Object.values(markers));
  }, [clusterer, markers]);


  const setMarkerRef = useCallback((marker, key) => {
    setMarkers(markers => {
      if ((marker && markers[key]) || (!marker && !markers[key]))
        return markers;

      if (marker) {
        return { ...markers, [key]: marker };
      } else {
        const { [key]: _, ...newMarkers } = markers;

        return newMarkers;
      }
    });
  }, []);

  return <>

    {items.map((item, index) => (
      <ProjectMarker
        key={item.Tyokohdenro}
        item={item}
        itemIndex={index}
        handleMarkerClick={handleMarkerClick}
        setMarkerRef={setMarkerRef}
      />
    ))}

  </>
};


export const MapPage = ({ setToken }) => {
  const dispatch = useDispatch();
  const [isLoading, setisLoading] = useState(false);

  const { locale } = useTranslate();
  const navigate = useNavigate();
  const envConfig = getEnvSettings();


  const [filterActive, setFilterActive] = useState(["3"]);

  const API_KEY = process.env.REACT_APP_MAPS_API_KEY //config.devMapsKey

  const [factoryData, setFactoryData] = useState([]);
  const [worksData, setWorksData] = useState([]);
  const [selectedMarker, setSelectedMarker] = useState(null);
  const [infoWindowShown, setInfoWindowShown] = useState(false);

  const [archiveYears, setArchiveYears] = useState([]);
  const [archiveFilter, setArchiveFilter] = useState([]);

  const [archiveYearsMenu, setArchiveYearsMenu] = useState([]);

  const [filteredProjects, setFilteredProjects] = useState([]);

  const [googleMapsLoaded, setGoogleMapsLoaded] = useState(false);


  // clicking a marker will set it as selected and show the infowindow
  const handleMarkerClick = useCallback((markerData) => {
    setSelectedMarker(markerData);
    setInfoWindowShown(true);
  }, []);

  // if the maps api closes the infowindow, we have to synchronize our state
  const handleCloseInfo = useCallback(() => {
    setInfoWindowShown(false);
    setSelectedMarker(null);
  }, []);



  const toggleFilter = (key) => {
    setFilterActive((prev) => {
      if (prev.includes(key)) {
        return prev.filter(item => item !== key);
      }
      return [...prev, key];
    });
  };

  const toggleArchiveFilter = (key) => {
    setArchiveFilter((prev) => {
      if (prev.includes(key)) {
        return prev.filter(item => item !== key);
      }
      return [...prev, key];
    });
  };



  const getMapProjects = () => {
    setisLoading(true);
    console.log("getMapProjects");

    let req_config = {
      method: "POST",
      url: `${envConfig.apiUrlPrefix}getMapProjects`,
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
    };

    apiSecure(req_config)
      .then((response) => {
        console.log("RESPONSE", response);
        setArchiveYears(response?.data?.data?.arkistoVuodet);
        setWorksData(response?.data?.data?.kohteet);
        setFactoryData(response?.data?.data?.tehtaat);

        setisLoading(false);
      })
      .catch((error) => {
        console.log("Error:", error);
        setisLoading(false);
      });
  };

  useEffect(() => {
    getMapProjects();
  }, []);


  useEffect(() => {
    if (archiveYears?.length <= 0) return

    let nArr = [
      {
        key: 'all',
        label: 'Kaikki',
        onClick: () => toggleArchiveFilter("Kaikki"),
      },
    ]

    archiveYears.map((item, index) => {
      let yearString = item.ArkistoVuosi.toString()

      nArr.push({
        key: yearString,
        label: yearString,
        onClick: () => toggleArchiveFilter(yearString),
      })
    });

    setArchiveYearsMenu(nArr)
  }, [archiveYears]);



  useEffect(() => {
    if (worksData.length >= 1) {
      let nArr = worksData;
      
      nArr = worksData.filter(itm => {
        // Perussuodattimet
        const matchesFilters =
          (filterActive.includes("1") && itm.Tila === 1) ||
          (filterActive.includes("2") && itm.Tila === 2) ||
          (filterActive.includes("3") && itm.Tila === 3);

        // Arkistosuodattimet
        const matchesArchive =
        itm.Tila === 5 &&
          (archiveFilter.includes("Kaikki") ||
            archiveFilter.includes(itm?.ValmistumisVuosi?.toString()));

        return matchesFilters || matchesArchive;
      });

      setFilteredProjects(nArr);
    }
  }, [filterActive, worksData, archiveFilter]);


  if (isLoading) {
    return <Loading />;
  }



  return (
    <div className="map-page-main">
      <div style={{ display: 'flex', flexDirection: 'column' }}>


        <div className="map-page-inner">
          <APIProvider apiKey={API_KEY} onLoad={() => {
            setGoogleMapsLoaded(true)
          }}>



            <Map
              id="map-custom-id"
              mapId={"a5c9eab71d5a0001"}
              style={{ width: "100%", height: "100%", borderRadius: "14px" }}
              defaultCenter={{ lat: 66.67, lng: 26.86 }} //Suomi
              defaultZoom={5}
              gestureHandling={"greedy"}
              disableDefaultUI={true}
              streetViewControl={true}
            >

              <MapCustomBtns id={"map-custom-id"} />

              <RenderFilters toggleFilter={toggleFilter} filterActive={filterActive} archiveFilter={archiveFilter}  archiveYearsMenu={archiveYearsMenu} />

              <RenderProjectMarkers id={"map-custom-id"} items={filteredProjects} handleMarkerClick={handleMarkerClick} />




              {factoryData.map((item) => {
                var newlat = parseFloat(item.lat.replace(/,/g, "."));
                var newlng = parseFloat(item.lng.replace(/,/g, "."));

                return (
                  <AdvancedMarker
                    key={item.Id}
                    position={{ lat: newlat, lng: newlng }}
                    zIndex={5000}
                  >
                    <img
                      src={item?.IkoniUrl}
                      style={{ width: '30px', height: '30px' }}
                    />
                  </AdvancedMarker>
                  /*          
                  <Marker
                    key={item.Id}
                    position={{ lat: newlat, lng: newlng }}
                    zIndex={999}
                    icon={{
                      url: item?.IkoniUrl,
                      scaledSize: window.google ? new window.google.maps.Size(30, 30) : null,
                      
                    }}
                  /> */
                )
              })}


              <RenderMapInfo infoWindowShown={infoWindowShown} selectedMarker={selectedMarker} handleClose={handleCloseInfo} />
            </Map>
          </APIProvider>
        </div>

      </div>

    </div>
  );
};
