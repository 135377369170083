import "./FactorySwitcher.css";
import { useDispatch, useSelector } from "react-redux";
import AllActions from "../../store/actions";
import { useTranslate } from "../Translate";
import Icon from "@ant-design/icons";

import { Dropdown, Menu, Space } from "antd";
import { useState, useEffect } from "react";
import { getEnvSettings } from "../../config/config";





const LocationSvg = () => (
  <svg width="10" height="11" viewBox="0 0 12 13" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g clipPath="url(#clip0_1433_337)">
      <path d="M6 1.62451C3.92977 1.62451 2.25 3.13646 2.25 4.99951C2.25 7.99951 6 12.1245 6 12.1245C6 12.1245 9.75 7.99951 9.75 4.99951C9.75 3.13646 8.07023 1.62451 6 1.62451ZM6 6.87451C5.70333 6.87451 5.41332 6.78654 5.16665 6.62172C4.91997 6.45689 4.72771 6.22263 4.61418 5.94854C4.50065 5.67445 4.47094 5.37285 4.52882 5.08188C4.5867 4.7909 4.72956 4.52363 4.93934 4.31385C5.14912 4.10407 5.41639 3.96121 5.70736 3.90333C5.99834 3.84546 6.29994 3.87516 6.57403 3.98869C6.84811 4.10222 7.08238 4.29448 7.2472 4.54116C7.41203 4.78783 7.5 5.07784 7.5 5.37451C7.49957 5.7722 7.34139 6.15348 7.06018 6.43469C6.77897 6.7159 6.39769 6.87408 6 6.87451Z" fill="#A0A0A0" />
    </g>
    <defs>
      <clipPath id="clip0_1433_337">
        <rect width="12" height="12" fill="white" transform="translate(0 0.875)" />
      </clipPath>
    </defs>
  </svg>
);

const LocIcon = (props) => <Icon component={LocationSvg} {...props} />;

export function FactoryMenu() {
  const { tehtaat, selectedFactory } = useSelector(
    (state) => state.currentUser.user
  );

  const envConfig = getEnvSettings();


  if (!envConfig.showFactorySel) return []


  let newArray = [];
  Object.keys(tehtaat).map((key) => {
    let factoryData = tehtaat[key];

    if (!factoryData.MobiiliKaytossa) return;

    newArray.push({
      label: (
        <p className="menuValue font-dm-medium" >
          {factoryData.name}
        </p>
      ),
      key: "factory_" + key,
    });
  });


  return ([{
    label: (
      <span className="displayValue font-dm-medium">
        {tehtaat[selectedFactory].name}
      </span>
    ),
    key: "factories",
    children: newArray
  }])
}



export function FactorySwitcher({ color, additionalClasses = "" }) {
  const dispatch = useDispatch();
  const { tehtaat, selectedFactory } = useSelector(
    (state) => state.currentUser.user
  );
  const [menuVisible, setMenuVisible] = useState(false);

  const { locale } = useTranslate();

  //console.log("tehtaat1", tehtaat);
  //console.log("selectedFactory", selectedFactory);

  const updateFactory = (id) => {
    console.log("updateFactory", id);
    const factoryNumber = id.replace("factory_", "");
    dispatch(AllActions.userActions.updateFactory(factoryNumber));
  };

  const onClick = ({ key }) => {
    console.log(`Click on item ${key}`);
    updateFactory(key);
    setMenuVisible(false);
  };


  return (
    <Dropdown
      className="lngDropdown"
      menu={{ items: FactoryMenu()[0].children, onClick }}
      trigger={["click"]}
      open={menuVisible}
      onOpenChange={(visible) => {
        setMenuVisible(visible);
      }}
      placement="bottomRight"
    >
      <p className="factorySelectorItem font-dm-regular">
        <LocIcon  /> {tehtaat[selectedFactory].name}
      </p>
    </Dropdown>
  );
}
